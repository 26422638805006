/**
 * Changing narration in this file will not automatically update the narration in the assessment.
 * Audio is cached as .mp3 files to prevent excessive use of the TTS API.  See `bin/cache_audio.js`
 */

const itemsByType = {
	letterIdentification: {
		numTrainingItemRetries: 1,
		trainingItemIds: [
			1098,
			1099
		],
		items: [
			{
				itemId: 1001,
				itemLabel: "CLO-LID-1",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.4,
				choices: [
					"a",
					"i",
					"d",
					"l",
					"v"
				],
				correctAnswer: "a",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> a <break time=\"250ms\" />  como en abeja.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10010,
				itemLabel: "CLO-LID-10",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.656,
				choices: [
					"k",
					"z",
					"r",
					"i",
					"j"
				],
				correctAnswer: "k",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> k <break time=\"250ms\" /> como en kilómetro.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10011,
				itemLabel: "CLO-LID-11",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.632,
				choices: [
					"M",
					"D",
					"X",
					"U",
					"B"
				],
				correctAnswer: "M",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> m <break time=\"250ms\" /> como en mujer.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10012,
				itemLabel: "CLO-LID-12",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.659,
				choices: [
					"n",
					"s",
					"z",
					"u",
					"x"
				],
				correctAnswer: "n",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> n <break time=\"250ms\" /> como en naranja.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10013,
				itemLabel: "CLO-LID-13",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.608,
				choices: [
					"ñ",
					"v",
					"h",
					"o",
					"q"
				],
				correctAnswer: "ñ",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> ñ <break time=\"250ms\" /> como en ñoño.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10014,
				itemLabel: "CLO-LID-14",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -2.134,
				choices: [
					"O",
					"A",
					"H",
					"R",
					"F"
				],
				correctAnswer: "O",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> o <break time=\"250ms\" /> como en ocho.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10015,
				itemLabel: "CLO-LID-15",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -0.79,
				choices: [
					"p",
					"z",
					"n",
					"t",
					"j"
				],
				correctAnswer: "p",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> p <break time=\"250ms\" /> como en pato.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10016,
				itemLabel: "CLO-LID-16",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.452,
				choices: [
					"Q",
					"F",
					"O",
					"A",
					"B"
				],
				correctAnswer: "Q",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> q <break time=\"250ms\" /> como en queso.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10017,
				itemLabel: "CLO-LID-17",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.593,
				choices: [
					"R",
					"L",
					"P",
					"S",
					"Z"
				],
				correctAnswer: "R",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> r <break time=\"250ms\" /> como en reloj.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10018,
				itemLabel: "CLO-LID-18",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.711,
				choices: [
					"s",
					"d",
					"j",
					"i",
					"q"
				],
				correctAnswer: "s",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> s <break time=\"250ms\" /> como en sandía.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10019,
				itemLabel: "CLO-LID-19",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.486,
				choices: [
					"t",
					"r",
					"z",
					"e",
					"k"
				],
				correctAnswer: "t",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> t <break time=\"250ms\" /> como en tasa.</prosody></voice></speak>"
				}
			},
			{
				itemId: 1002,
				itemLabel: "CLO-LID-2",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.407,
				choices: [
					"B",
					"Y",
					"D",
					"C",
					"X"
				],
				correctAnswer: "B",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> b larga o b grande <break time=\"250ms\" />  como en barco.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10020,
				itemLabel: "CLO-LID-20",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.747,
				choices: [
					"U",
					"A",
					"V",
					"S",
					"X"
				],
				correctAnswer: "U",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra, \"u\", como en uña</prosody></voice></speak>"
				}
			},
			{
				itemId: 10021,
				itemLabel: "CLO-LID-21",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -0.514,
				choices: [
					"V",
					"G",
					"T",
					"U",
					"P"
				],
				correctAnswer: "V",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> b corta o b chica<break time=\"250ms\" /> como en vaso.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10022,
				itemLabel: "CLO-LID-22",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.511,
				choices: [
					"w",
					"x",
					"v",
					"s",
					"f"
				],
				correctAnswer: "w",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra, doble u, como en uáfLe</prosody></voice></speak>"
				}
			},
			{
				itemId: 10023,
				itemLabel: "CLO-LID-23",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.876,
				choices: [
					"X",
					"W",
					"Y",
					"T",
					"F"
				],
				correctAnswer: "X",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> x <break time=\"250ms\" /> como en xilófono.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10024,
				itemLabel: "CLO-LID-24",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.243,
				choices: [
					"Y",
					"Z",
					"S",
					"O",
					"F"
				],
				correctAnswer: "Y",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> y griega <break time=\"250ms\" /> como en yogurt.</prosody></voice></speak>"
				}
			},
			{
				itemId: 10025,
				itemLabel: "CLO-LID-25",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.469,
				choices: [
					"z",
					"y",
					"r",
					"u",
					"w"
				],
				correctAnswer: "z",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> z <break time=\"250ms\" /> como en zoológico.</prosody></voice></speak>"
				}
			},
			{
				itemId: 1003,
				itemLabel: "CLO-LID-3",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.69,
				choices: [
					"C",
					"T",
					"O",
					"A",
					"W"
				],
				correctAnswer: "C",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> c <break time=\"250ms\" /> como en copa.</prosody></voice></speak>"
				}
			},
			{
				itemId: 1004,
				itemLabel: "CLO-LID-4",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -0.851,
				choices: [
					"d",
					"n",
					"b",
					"r",
					"z"
				],
				correctAnswer: "d",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> d <break time=\"250ms\" /> como en dedo.</prosody></voice></speak>"
				}
			},
			{
				itemId: 1005,
				itemLabel: "CLO-LID-5",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.387,
				choices: [
					"F",
					"J",
					"D",
					"A",
					"Q"
				],
				correctAnswer: "F",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> f <break time=\"250ms\" /> como en febrero.</prosody></voice></speak>"
				}
			},
			{
				itemId: 1006,
				itemLabel: "CLO-LID-6",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.191,
				choices: [
					"g",
					"f",
					"y",
					"a",
					"b"
				],
				correctAnswer: "g",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> g <break time=\"250ms\" /> como en gris.</prosody></voice></speak>"
				}
			},
			{
				itemId: 1007,
				itemLabel: "CLO-LID-7",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.494,
				choices: [
					"H",
					"F",
					"D",
					"M",
					"J"
				],
				correctAnswer: "H",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> h <break time=\"250ms\" /> como en huevo.</prosody></voice></speak>"
				}
			},
			{
				itemId: 1008,
				itemLabel: "CLO-LID-8",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.237,
				choices: [
					"i",
					"e",
					"l",
					"c",
					"y"
				],
				correctAnswer: "i",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> i <break time=\"250ms\" /> como en iguana.</prosody></voice></speak>"
				}
			},
			{
				itemId: 1009,
				itemLabel: "CLO-LID-9",
				itemType: "letterIdentification",
				itemClass: "active",
				difficulty: -1.266,
				choices: [
					"J",
					"F",
					"I",
					"S",
					"K"
				],
				correctAnswer: "J",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra <break time=\"250ms\" /> j <break time=\"250ms\" /> como en jirafa.</prosody></voice></speak>"
				}
			},
			{
				itemId: 1098,
				itemLabel: "CLO-LID-T1",
				itemType: "letterIdentification",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"b",
					"u",
					"v",
					"s",
					"e"
				],
				correctAnswer: "e",
				narration: {
					instructions: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Mira las siguientes letras del alfabeto. Escoge la letra que me escuchas decir.</prosody></voice></speak>",
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra, \"E\" como en \"escuela\". \"E\"</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Muy bien, escogiste la letra \"E\". Intentemos más!</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. Acuérdate que la letra \"e\" es curva con un palo en medio. Intentemos otra vez.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Muy bien, escogiste la letra \"E\". Intentemos más!</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Intentemos algunos más!</prosody></voice></speak>"
						}
					]
				}
			},
			{
				itemId: 1099,
				itemLabel: "CLO-LID-T2",
				itemType: "letterIdentification",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"C",
					"L",
					"Q",
					"O",
					"B"
				],
				correctAnswer: "L",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra \"L\", como en \"leche\". \"L\" .</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Escogiste la letra \"L\". Ahora, inténtalo tú!</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. Acuérdate, la letra \"L\" tiene un palo largo. Intentemos otra vez.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Escogiste la letra \"L\". Ahora inténtalo tú.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Ahora, inténtalo tú!</prosody></voice></speak>"
						}
					]
				}
			}
		]
	},
	letters2Word: {
		numTrainingItemRetries: 1,
		trainingItemIds: [
			3098,
			3099
		],
		items: [
			{
				itemId: 3098,
				itemLabel: "CLO-SP-T1",
				itemType: "letters2Word",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"a",
					"s",
					"c",
					"a"
				],
				correctAnswer: "casa",
				narration: {
					instructions: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Mira las siguientes letras. Estas letras se pueden reorganizar para formar una palabra. ¡Vamos a probar! Verás una barrita verde que indica dónde van a ir tus letras. A veces tendrás solo las letras que necesitas y a veces tendrás letras extras. Solo usa las letras que necesitas para deletrear la palabra.</prosody></voice></speak>",
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Reorganiza las letras para que la palabra diga  \"casa\". \"casa\".</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Muy bien, deletreaste la palabra \"casa\", \"c.-a.-s.-a\", \"casa\". ¿Viste la barrita verde? La barrita te muestra donde van a ir las letras.</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. \"casa\" se deletrea \"c.-a.-s.-a\" . ¿Viste la barrita verde? La barrita te muestra donde van a ir tus letras. Intentémoslo otra vez. Mira las siguientes letras. Estas letras se pueden reorganizar para formar una palabra. ¡Vamos a probar! Verás una barrita verde que indica dónde van a ir tus letras.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Muy bien, deletreaste la palabra \"casa\", \"c.-a.-s.-a\", \"casa\". ¿Viste la barrita verde? La barrita te muestra donde van a ir las letras.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Intentemos otro.</prosody></voice></speak>"
						}
					]
				}
			},
			{
				itemId: 3099,
				itemLabel: "CLO-SP-T2",
				itemType: "letters2Word",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"l",
					"e",
					"p",
					"o"
				],
				correctAnswer: "pelo",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Reorganiza las letras para que la palabra diga, \"pelo\". \"pelo\"</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Deletreaste la palabra \"pelo\", \"p. -e. -l. -o\", \"pelo\". Ahora inténtalo tú. Acuérdate que a veces tendrás solo las letras que necesitas y a veces tendrás letras extras. Solo usa las letras que necesitas para deletrear la palabra.</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. \"pelo\" se deletrea \"p.-e.-l.-o\". Ahora inténtalo tú. Acuérdate que a veces tendrás solo las letras que necesitas y a veces tendrás letras extras. Solo usa las letras que necesitas para deletrear la palabra.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Deletreaste la palabra \"pelo\", \"p. -e. -l. -o\", \"pelo\". Ahora inténtalo tú. Acuérdate que a veces tendrás solo las letras que necesitas y a veces tendrás letras extras. Solo usa las letras que necesitas para deletrear la palabra.</prosody></voice></speak>"
						}
					]
				}
			},
			{
				itemId: 3001,
				itemLabel: "CLO-SP-1",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "bota",
				choices: [
					"a",
					"b",
					"t",
					"o"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">bota <break time=\"500ms\" /> bota </prosody></prosody></voice></speak>"
				},
				difficulty: -0.896
			},
			{
				itemId: 3002,
				itemLabel: "CLO-SP-2",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "pato",
				choices: [
					"t",
					"o",
					"p",
					"a"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">pato <break time=\"500ms\" /> pato </prosody></prosody></voice></speak>"
				},
				difficulty: -0.785
			},
			{
				itemId: 3003,
				itemLabel: "CLO-SP-3",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "gato",
				choices: [
					"o",
					"a",
					"g",
					"t"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">gato <break time=\"500ms\" /> gato </prosody></prosody></voice></speak>"
				},
				difficulty: -0.666
			},
			{
				itemId: 3004,
				itemLabel: "CLO-SP-4",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "cama",
				choices: [
					"a",
					"c",
					"a",
					"m"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">cama <break time=\"500ms\" /> cama </prosody></prosody></voice></speak>"
				},
				difficulty: -0.676
			},
			{
				itemId: 3005,
				itemLabel: "CLO-SP-5",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "mano",
				choices: [
					"n",
					"m",
					"o",
					"a"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">mano <break time=\"500ms\" /> mano </prosody></prosody></voice></speak>"
				},
				difficulty: -0.772
			},
			{
				itemId: 3006,
				itemLabel: "CLO-SP-6",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "agua",
				choices: [
					"a",
					"u",
					"a",
					"g"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">agua <break time=\"500ms\" /> agua </prosody></prosody></voice></speak>"
				},
				difficulty: -0.165
			},
			{
				itemId: 3007,
				itemLabel: "CLO-SP-7",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "cara",
				choices: [
					"r",
					"a",
					"c",
					"a"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">cara <break time=\"500ms\" /> cara </prosody></prosody></voice></speak>"
				},
				difficulty: -0.696
			},
			{
				itemId: 3008,
				itemLabel: "CLO-SP-8",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "nube",
				choices: [
					"e",
					"u",
					"n",
					"b"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">nube <break time=\"500ms\" /> nube </prosody></prosody></voice></speak>"
				},
				difficulty: -0.523
			},
			{
				itemId: 3009,
				itemLabel: "CLO-SP-9",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "rojo",
				choices: [
					"j",
					"o",
					"r",
					"o"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">rojo <break time=\"500ms\" /> rojo </prosody></prosody></voice></speak>"
				},
				difficulty: -0.593
			},
			{
				itemId: 30010,
				itemLabel: "CLO-SP-10",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "vaso",
				choices: [
					"o",
					"s",
					"v",
					"a"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">vaso <break time=\"500ms\" /> vaso </prosody></prosody></voice></speak>"
				},
				difficulty: -0.547
			},
			{
				itemId: 30011,
				itemLabel: "CLO-SP-11",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "caro",
				choices: [
					"o",
					"r",
					"a",
					"c"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">caro <break time=\"500ms\" /> caro </prosody></prosody></voice></speak>"
				},
				difficulty: -0.624
			},
			{
				itemId: 30012,
				itemLabel: "CLO-SP-12",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "vela",
				choices: [
					"l",
					"a",
					"v",
					"e"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">vela <break time=\"500ms\" /> vela </prosody></prosody></voice></speak>"
				},
				difficulty: -0.656
			},
			{
				itemId: 30013,
				itemLabel: "CLO-SP-13",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "hoja",
				choices: [
					"a",
					"j",
					"h",
					"o"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">hoja <break time=\"500ms\" /> hoja </prosody></prosody></voice></speak>"
				},
				difficulty: 0.162
			},
			{
				itemId: 30014,
				itemLabel: "CLO-SP-14",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "dedo",
				choices: [
					"o",
					"d",
					"d",
					"e"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">dedo <break time=\"500ms\" /> dedo </prosody></prosody></voice></speak>"
				},
				difficulty: -0.688
			},
			{
				itemId: 30015,
				itemLabel: "CLO-SP-15",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "arte",
				choices: [
					"t",
					"r",
					"a",
					"e"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">arte <break time=\"500ms\" /> arte </prosody></prosody></voice></speak>"
				},
				difficulty: -0.333
			},
			{
				itemId: 30016,
				itemLabel: "CLO-SP-16",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "restar",
				choices: [
					"s",
					"t",
					"a",
					"r",
					"e",
					"r"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">restar <break time=\"500ms\" /> restar </prosody></prosody></voice></speak>"
				},
				difficulty: 0.489
			},
			{
				itemId: 30017,
				itemLabel: "CLO-SP-17",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "lavar",
				choices: [
					"a",
					"r",
					"v",
					"l",
					"a"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">lavar <break time=\"500ms\" /> lavar </prosody></prosody></voice></speak>"
				},
				difficulty: -0.293
			},
			{
				itemId: 30018,
				itemLabel: "CLO-SP-18",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "avión",
				choices: [
					"v",
					"i",
					"n",
					"ó",
					"a"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">avión <break time=\"500ms\" /> avión </prosody></prosody></voice></speak>"
				},
				difficulty: -0.102
			},
			{
				itemId: 30019,
				itemLabel: "CLO-SP-19",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "lápiz",
				choices: [
					"z",
					"á",
					"i",
					"l",
					"p"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">lápiz <break time=\"500ms\" /> lápiz </prosody></prosody></voice></speak>"
				},
				difficulty: -0.514
			},
			{
				itemId: 30020,
				itemLabel: "CLO-SP-20",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "viaje",
				choices: [
					"v",
					"j",
					"e",
					"a",
					"i"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">viaje <break time=\"500ms\" /> viaje </prosody></prosody></voice></speak>"
				},
				difficulty: 0.205
			},
			{
				itemId: 30021,
				itemLabel: "CLO-SP-21",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "fruta",
				choices: [
					"a",
					"r",
					"t",
					"f",
					"u"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">fruta <break time=\"500ms\" /> fruta </prosody></prosody></voice></speak>"
				},
				difficulty: -0.06
			},
			{
				itemId: 30022,
				itemLabel: "CLO-SP-22",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "hielo",
				choices: [
					"o",
					"h",
					"i",
					"l",
					"e"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">hielo <break time=\"500ms\" /> hielo </prosody></prosody></voice></speak>"
				},
				difficulty: 0.618
			},
			{
				itemId: 30023,
				itemLabel: "CLO-SP-23",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "perro",
				choices: [
					"r",
					"p",
					"l",
					"e",
					"r",
					"o"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">perro <break time=\"500ms\" /> perro </prosody></prosody></voice></speak>"
				},
				difficulty: 0.092
			},
			{
				itemId: 30024,
				itemLabel: "CLO-SP-24",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "llave",
				choices: [
					"v",
					"l",
					"e",
					"n",
					"l",
					"a"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">llave <break time=\"500ms\" /> llave </prosody></prosody></voice></speak>"
				},
				difficulty: 0.173
			},
			{
				itemId: 30025,
				itemLabel: "CLO-SP-25",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "regla",
				choices: [
					"l",
					"a",
					"p",
					"g",
					"e",
					"r"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">regla <break time=\"500ms\" /> regla </prosody></prosody></voice></speak>"
				},
				difficulty: 0.157
			},
			{
				itemId: 30026,
				itemLabel: "CLO-SP-26",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "julio",
				choices: [
					"o",
					"u",
					"k",
					"i",
					"j",
					"l"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">julio <break time=\"500ms\" /> julio </prosody></prosody></voice></speak>"
				},
				difficulty: 0.14
			},
			{
				itemId: 30027,
				itemLabel: "CLO-SP-27",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "burro",
				choices: [
					"r",
					"u",
					"b",
					"o",
					"r",
					"c"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">burro <break time=\"500ms\" /> burro </prosody></prosody></voice></speak>"
				},
				difficulty: 0.537
			},
			{
				itemId: 30028,
				itemLabel: "CLO-SP-28",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "comer",
				choices: [
					"r",
					"m",
					"f",
					"c",
					"o",
					"e"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">comer <break time=\"500ms\" /> comer </prosody></prosody></voice></speak>"
				},
				difficulty: -0.261
			},
			{
				itemId: 30029,
				itemLabel: "CLO-SP-29",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "jugar",
				choices: [
					"r",
					"j",
					"g",
					"a",
					"t",
					"u",
					"s"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">jugar <break time=\"500ms\" /> jugar </prosody></prosody></voice></speak>"
				},
				difficulty: -0.121
			},
			{
				itemId: 30030,
				itemLabel: "CLO-SP-30",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "hacer",
				choices: [
					"c",
					"e",
					"a",
					"h",
					"r",
					"j"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">hacer <break time=\"500ms\" /> hacer </prosody></prosody></voice></speak>"
				},
				difficulty: 0.792
			},
			{
				itemId: 30031,
				itemLabel: "CLO-SP-31",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "arroz",
				choices: [
					"r",
					"z",
					"a",
					"f",
					"r",
					"o",
					"c"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">arroz <break time=\"500ms\" /> arroz </prosody></prosody></voice></speak>"
				},
				difficulty: 1.146
			},
			{
				itemId: 30032,
				itemLabel: "CLO-SP-32",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "bolsa",
				choices: [
					"s",
					"y",
					"a",
					"l",
					"b",
					"o",
					"v"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">bolsa <break time=\"500ms\" /> bolsa </prosody></prosody></voice></speak>"
				},
				difficulty: 0.138
			},
			{
				itemId: 30033,
				itemLabel: "CLO-SP-33",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "parar",
				choices: [
					"r",
					"p",
					"u",
					"a",
					"l",
					"r",
					"a"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">parar <break time=\"500ms\" /> parar </prosody></prosody></voice></speak>"
				},
				difficulty: 0.447
			},
			{
				itemId: 30034,
				itemLabel: "CLO-SP-34",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "mirar",
				choices: [
					"a",
					"r",
					"m",
					"i",
					"r",
					"e",
					"x"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">mirar <break time=\"500ms\" /> mirar </prosody></prosody></voice></speak>"
				},
				difficulty: 0.353
			},
			{
				itemId: 30035,
				itemLabel: "CLO-SP-35",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "cielo",
				choices: [
					"i",
					"l",
					"e",
					"s",
					"o",
					"c",
					"a"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">cielo <break time=\"500ms\" /> cielo </prosody></prosody></voice></speak>"
				},
				difficulty: 0.62
			},
			{
				itemId: 30036,
				itemLabel: "CLO-SP-36",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "llama",
				choices: [
					"m",
					"y",
					"a",
					"l",
					"a",
					"t",
					"l"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">llama <break time=\"500ms\" /> llama </prosody></prosody></voice></speak>"
				},
				difficulty: 0.82
			},
			{
				itemId: 30037,
				itemLabel: "CLO-SP-37",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "feliz",
				choices: [
					"e",
					"s",
					"l",
					"f",
					"i",
					"x",
					"z"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">feliz <break time=\"500ms\" /> feliz </prosody></prosody></voice></speak>"
				},
				difficulty: 0.111
			},
			{
				itemId: 30038,
				itemLabel: "CLO-SP-38",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "reloj",
				choices: [
					"j",
					"e",
					"x",
					"r",
					"l",
					"o",
					"p"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">reloj <break time=\"500ms\" /> reloj </prosody></prosody></voice></speak>"
				},
				difficulty: 0.721
			},
			{
				itemId: 30039,
				itemLabel: "CLO-SP-39",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "salón",
				choices: [
					"ó",
					"w",
					"a",
					"s",
					"l",
					"c",
					"n"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">salón <break time=\"500ms\" /> salón </prosody></prosody></voice></speak>"
				},
				difficulty: -0.164
			},
			{
				itemId: 30040,
				itemLabel: "CLO-SP-40",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "abrir",
				choices: [
					"v",
					"r",
					"i",
					"a",
					"b",
					"o",
					"r"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">abrir<break time=\"500ms\" /> abrir </prosody></prosody></voice></speak>"
				},
				difficulty: 0.463
			},
			{
				itemId: 30041,
				itemLabel: "CLO-SP-41",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "barco",
				choices: [
					"v",
					"o",
					"b",
					"r",
					"a",
					"c",
					"p"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">barco<break time=\"500ms\" /> barco </prosody></prosody></voice></speak>"
				},
				difficulty: 0.034
			},
			{
				itemId: 30042,
				itemLabel: "CLO-SP-42",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "gastar",
				choices: [
					"a",
					"g",
					"t",
					"a",
					"m",
					"s",
					"r",
					"y"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">gastar <break time=\"500ms\" /> gastar </prosody></prosody></voice></speak>"
				},
				difficulty: 0.205
			},
			{
				itemId: 30043,
				itemLabel: "CLO-SP-43",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "enojar",
				choices: [
					"j",
					"a",
					"o",
					"r",
					"e",
					"z",
					"n",
					"l"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">enojar <break time=\"500ms\" /> enojar </prosody></prosody></voice></speak>"
				},
				difficulty: -0.058
			},
			{
				itemId: 30044,
				itemLabel: "CLO-SP-44",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "lentes",
				choices: [
					"s",
					"t",
					"u",
					"m",
					"l",
					"e",
					"n",
					"e"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">lentes <break time=\"500ms\" /> lentes </prosody></prosody></voice></speak>"
				},
				difficulty: -0.154
			},
			{
				itemId: 30045,
				itemLabel: "CLO-SP-45",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "dinero",
				choices: [
					"g",
					"j",
					"d",
					"o",
					"n",
					"i",
					"e",
					"r"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">dinero <break time=\"500ms\" /> dinero </prosody></prosody></voice></speak>"
				},
				difficulty: -0.156
			},
			{
				itemId: 30046,
				itemLabel: "CLO-SP-46",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "ciudad",
				choices: [
					"p",
					"d",
					"i",
					"c",
					"n",
					"a",
					"u",
					"d"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">ciudad <break time=\"500ms\" /> ciudad </prosody></prosody></voice></speak>"
				},
				difficulty: 0.538
			},
			{
				itemId: 30047,
				itemLabel: "CLO-SP-47",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "parque",
				choices: [
					"q",
					"y",
					"e",
					"p",
					"u",
					"d",
					"a",
					"r"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">parque <break time=\"500ms\" /> parque </prosody></prosody></voice></speak>"
				},
				difficulty: 0.11
			},
			{
				itemId: 30048,
				itemLabel: "CLO-SP-48",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "alumno",
				choices: [
					"v",
					"t",
					"n",
					"l",
					"a",
					"m",
					"u",
					"o"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">alumno <break time=\"500ms\" /> alumno </prosody></prosody></voice></speak>"
				},
				difficulty: 0.951
			},
			{
				itemId: 30049,
				itemLabel: "CLO-SP-49",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "volcán",
				choices: [
					"o",
					"k",
					"l",
					"n",
					"á",
					"c",
					"h",
					"v"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">volcán <break time=\"500ms\" /> volcán </prosody></prosody></voice></speak>"
				},
				difficulty: 0.392
			},
			{
				itemId: 30050,
				itemLabel: "CLO-SP-50",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "dibujo",
				choices: [
					"o",
					"x",
					"d",
					"j",
					"i",
					"b",
					"a",
					"u"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">dibujo <break time=\"500ms\" /> dibujo </prosody></prosody></voice></speak>"
				},
				difficulty: 0.116
			},
			{
				itemId: 30051,
				itemLabel: "CLO-SP-51",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "boleto",
				choices: [
					"v",
					"l",
					"i",
					"e",
					"t",
					"o",
					"b",
					"o"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">boleto <break time=\"500ms\" /> boleto </prosody></prosody></voice></speak>"
				},
				difficulty: 0.069
			},
			{
				itemId: 30052,
				itemLabel: "CLO-SP-52",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "camión",
				choices: [
					"m",
					"i",
					"q",
					"c",
					"a",
					"ó",
					"d",
					"n"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">camión <break time=\"500ms\" /> camión </prosody></prosody></voice></speak>"
				},
				difficulty: 0.252
			},
			{
				itemId: 30053,
				itemLabel: "CLO-SP-53",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "moneda",
				choices: [
					"d",
					"e",
					"t",
					"a",
					"n",
					"o",
					"z",
					"m"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">moneda <break time=\"500ms\" /> moneda </prosody></prosody></voice></speak>"
				},
				difficulty: 0.041
			},
			{
				itemId: 30054,
				itemLabel: "CLO-SP-54",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "llenar",
				choices: [
					"y",
					"e",
					"l",
					"n",
					"a",
					"r",
					"l",
					"o"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">llenar <break time=\"500ms\" /> llenar </prosody></prosody></voice></speak>"
				},
				difficulty: 0.831
			},
			{
				itemId: 30055,
				itemLabel: "CLO-SP-55",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "sillas",
				choices: [
					"c",
					"a",
					"i",
					"l",
					"a",
					"s",
					"l",
					"s"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">sillas <break time=\"500ms\" /> sillas </prosody></prosody></voice></speak>"
				},
				difficulty: 0.611
			},
			{
				itemId: 3056,
				itemLabel: "CLO-SP-56",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "invitación",
				choices: [
					"t",
					"i",
					"v",
					"i",
					"n",
					"b",
					"s",
					"n",
					"a",
					"c",
					"ó",
					"i"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">invitación <break time=\"500ms\" /> invitación </prosody></prosody></voice></speak>"
				},
				difficulty: 1.157
			},
			{
				itemId: 3057,
				itemLabel: "CLO-SP-57",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "vehículo",
				choices: [
					"e",
					"o",
					"v",
					"h",
					"b",
					"í",
					"u",
					"c",
					"l",
					"j"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">vehículo <break time=\"500ms\" /> vehículo </prosody></prosody></voice></speak>"
				},
				difficulty: 2.713
			},
			{
				itemId: 3058,
				itemLabel: "CLO-SP-58",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "agradable",
				choices: [
					"a",
					"b",
					"d",
					"g",
					"l",
					"a",
					"v",
					"r",
					"e",
					"a",
					"j"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">agradable <break time=\"500ms\" /> agradable </prosody></prosody></voice></speak>"
				},
				difficulty: 0.957
			},
			{
				itemId: 3059,
				itemLabel: "CLO-SP-59",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "cicatriz",
				choices: [
					"c",
					"a",
					"i",
					"k",
					"c",
					"t",
					"i",
					"z",
					"s",
					"r"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">cicatriz <break time=\"500ms\" /> cicatriz </prosody></prosody></voice></speak>"
				},
				difficulty: 1.974
			},
			{
				itemId: 3060,
				itemLabel: "CLO-SP-60",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "oxígeno",
				choices: [
					"o",
					"n",
					"j",
					"x",
					"g",
					"í",
					"e",
					"o",
					"h"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">oxígeno <break time=\"500ms\" /> oxígeno </prosody></prosody></voice></speak>"
				},
				difficulty: 1.644
			},
			{
				itemId: 3061,
				itemLabel: "CLO-SP-61",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "brújula",
				choices: [
					"l",
					"v",
					"r",
					"b",
					"u",
					"ú",
					"a",
					"j",
					"g"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">brújula <break time=\"500ms\" /> brújula </prosody></prosody></voice></speak>"
				},
				difficulty: 1.143
			},
			{
				itemId: 3062,
				itemLabel: "CLO-SP-62",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "juguetón",
				choices: [
					"g",
					"n",
					"j",
					"u",
					"e",
					"u",
					"ó",
					"t",
					"h",
					"g"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">juguetón <break time=\"500ms\" /> juguetón </prosody></prosody></voice></speak>"
				},
				difficulty: 1.681
			},
			{
				itemId: 3063,
				itemLabel: "CLO-SP-63",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "subrayar",
				choices: [
					"y",
					"s",
					"r",
					"b",
					"l",
					"u",
					"a",
					"l",
					"r",
					"a"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">subrayar <break time=\"500ms\" /> subrayar </prosody></prosody></voice></speak>"
				},
				difficulty: 1.224
			},
			{
				itemId: 3064,
				itemLabel: "CLO-SP-64",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "orquesta",
				choices: [
					"k",
					"o",
					"a",
					"r",
					"t",
					"c",
					"q",
					"s",
					"e",
					"u"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">orquesta <break time=\"500ms\" /> orquesta </prosody></prosody></voice></speak>"
				},
				difficulty: 1.111
			},
			{
				itemId: 3065,
				itemLabel: "CLO-SP-65",
				itemType: "letters2Word",
				itemClass: "active",
				correctAnswer: "botella",
				choices: [
					"l",
					"b",
					"y",
					"t",
					"o",
					"a",
					"l",
					"e",
					"v"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Deletrea la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">botella <break time=\"500ms\" /> botella </prosody></prosody></voice></speak>"
				},
				difficulty: 0.698
			}
		]
	},
	soundIdentification: {
		numTrainingItemRetries: 1,
		trainingItemIds: [
			2098,
			2099
		],
		items: [
			{
				itemId: 2001,
				itemLabel: "CLO-SOID-1",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.175,
				correctAnswer: "V",
				choices: [
					"V",
					"A",
					"O",
					"N",
					"L"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"v\"> v </phoneme> </prosody><break time=\"250ms\" /> como en <break time=\"50ms\" />vaca.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20010,
				itemLabel: "CLO-SOID-10",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.273,
				correctAnswer: "K",
				choices: [
					"K",
					"Z",
					"T",
					"U",
					"M"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"k\"> k </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />kiwi.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20011,
				itemLabel: "CLO-SOID-11",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.13,
				correctAnswer: "Q",
				choices: [
					"Q",
					"B",
					"H",
					"J",
					"V"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"k\"> k </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />queso.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20012,
				itemLabel: "CLO-SOID-12",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.087,
				correctAnswer: "L",
				choices: [
					"L",
					"B",
					"F",
					"N",
					"P"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ll\"> ll </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />limon.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20013,
				itemLabel: "CLO-SOID-13",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.609,
				correctAnswer: "M",
				choices: [
					"M",
					"U",
					"I",
					"K",
					"V"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"mm\"> mm </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />mamá.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20014,
				itemLabel: "CLO-SOID-14",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.308,
				correctAnswer: "N",
				choices: [
					"N",
					"R",
					"D",
					"C",
					"H"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"nn\"> nn </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />nube.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20015,
				itemLabel: "CLO-SOID-15",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.67,
				correctAnswer: "Ñ",
				choices: [
					"Ñ",
					"A",
					"X",
					"S",
					"B"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ɲə\"> ɲə </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />ñoño.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20016,
				itemLabel: "CLO-SOID-16",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.685,
				correctAnswer: "O",
				choices: [
					"O",
					"I",
					"Y",
					"J",
					"F"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"oo\"> oo </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />oso.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20017,
				itemLabel: "CLO-SOID-17",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.457,
				correctAnswer: "P",
				choices: [
					"P",
					"Z",
					"J",
					"Q",
					"C"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"pɒ\"> pɒ </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />pié.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20018,
				itemLabel: "CLO-SOID-18",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.246,
				correctAnswer: "R",
				choices: [
					"R",
					"M",
					"V",
					"G",
					"D"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"r\"> ere </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />ratón.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20019,
				itemLabel: "CLO-SOID-19",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.745,
				correctAnswer: "S",
				choices: [
					"S",
					"T",
					"D",
					"L",
					"R"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ss\"> ss </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />sapo.</prosody></voice></speak>"
				}
			},
			{
				itemId: 2002,
				itemLabel: "CLO-SOID-2",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.506,
				correctAnswer: "D",
				choices: [
					"D",
					"X",
					"K",
					"U",
					"S"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"de\"> de </phoneme> </prosody><break time=\"250ms\" /> como en <break time=\"50ms\" />dinero.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20020,
				itemLabel: "CLO-SOID-20",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.451,
				correctAnswer: "C",
				choices: [
					"C",
					"P",
					"M",
					"G",
					"W"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ss\"> ss </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />cinta.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20021,
				itemLabel: "CLO-SOID-21",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.456,
				correctAnswer: "Z",
				choices: [
					"Z",
					"F",
					"U",
					"B",
					"Ñ"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ss\"> ss </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />zapato.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20022,
				itemLabel: "CLO-SOID-22",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.275,
				correctAnswer: "T",
				choices: [
					"T",
					"J",
					"X",
					"Q",
					"L"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"te\"> te </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />tijera.</prosody></voice></speak>"
				}
			},
			{
				itemId: 20023,
				itemLabel: "CLO-SOID-23",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.474,
				correctAnswer: "U",
				choices: [
					"U",
					"E",
					"B",
					"C",
					"K"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">escoge la letra que hace el sonido. u. como en uva</prosody></voice></speak>"
				}
			},
			{
				itemId: 20024,
				itemLabel: "CLO-SOID-24",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -0.859,
				correctAnswer: "Y",
				choices: [
					"Y",
					"H",
					"Q",
					"B",
					"P"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ʎa\"> ʎa </phoneme> </prosody><break time=\"250ms\" /> como en <break time=\"50ms\" />yema.</prosody></voice></speak>"
				}
			},
			{
				itemId: 2003,
				itemLabel: "CLO-SOID-3",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.382,
				correctAnswer: "E",
				choices: [
					"E",
					"A",
					"C",
					"M",
					"Ñ"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"e\"> e </phoneme> </prosody><break time=\"250ms\" /> como en <break time=\"50ms\" />elefante.</prosody></voice></speak>"
				}
			},
			{
				itemId: 2004,
				itemLabel: "CLO-SOID-4",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.308,
				correctAnswer: "F",
				choices: [
					"F",
					"O",
					"Z",
					"I",
					"P"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"f\"> f </phoneme> </prosody><break time=\"250ms\" /> como en <break time=\"50ms\" />fruta.</prosody></voice></speak>"
				}
			},
			{
				itemId: 2005,
				itemLabel: "CLO-SOID-5",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.435,
				correctAnswer: "G",
				choices: [
					"G",
					"X",
					"F",
					"A",
					"V"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"gʌ\"> gʌ </phoneme> </prosody><break time=\"250ms\" /> como en <break time=\"50ms\" />gato.</prosody></voice></speak>"
				}
			},
			{
				itemId: 2006,
				itemLabel: "CLO-SOID-6",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -0.915,
				correctAnswer: "G",
				choices: [
					"G",
					"B",
					"K",
					"L",
					"R"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"x\"> x </phoneme> </prosody><break time=\"250ms\" /> como en <break time=\"50ms\" />gelatina.</prosody></voice></speak>"
				}
			},
			{
				itemId: 2007,
				itemLabel: "CLO-SOID-7",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -0.517,
				correctAnswer: "J",
				choices: [
					"J",
					"H",
					"T",
					"O",
					"Ñ"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"x\"> x </phoneme> </prosody><break time=\"250ms\" /> como en <break time=\"50ms\" />jugo.</prosody></voice></speak>"
				}
			},
			{
				itemId: 2008,
				itemLabel: "CLO-SOID-8",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.448,
				correctAnswer: "I",
				choices: [
					"I",
					"O",
					"D",
					"F",
					"R"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"i\"> i </phoneme> </prosody><break time=\"250ms\" /> como en <break time=\"50ms\" />isla.</prosody></voice></speak>"
				}
			},
			{
				itemId: 2009,
				itemLabel: "CLO-SOID-9",
				itemType: "soundIdentification",
				itemClass: "active",
				difficulty: -1.556,
				correctAnswer: "C",
				choices: [
					"C",
					"Z",
					"V",
					"Ñ",
					"B"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"k\"> k </phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />casa.</prosody></voice></speak>"
				}
			},
			{
				itemId: 2098,
				itemLabel: "CLO-SOID-T1",
				itemType: "soundIdentification",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"J",
					"O",
					"A",
					"M",
					"V"
				],
				correctAnswer: "A",
				narration: {
					instructions: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Mira las siguientes letras del alfabeto. Yo voy a hacer un sonido, y quiero que escojas la letra que hace este sonido. </prosody></voice></speak>",
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido, \"a\". como en \"árbol\". \"a\"</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Escogiste la letra \"A\".</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. El sonido \"a\". van con la letra \"A\". Intentémoslo otra vez.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Escogiste la letra \"A\".</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Intentemos otro.</prosody></voice></speak>"
						}
					]
				}
			},
			{
				itemId: 2099,
				itemLabel: "CLO-SOID-T2",
				itemType: "soundIdentification",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"B",
					"U",
					"J",
					"L",
					"R"
				],
				correctAnswer: "B",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la letra que hace el sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"b\"> b </phoneme> </prosody><break time=\"250ms\" /> como en <break time=\"50ms\" />burro.</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Muy bien! Escogiste la letra “B”. Ahora inténtalo tú.</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. El sonido<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"b\"> b </phoneme> </prosody><break time=\"250ms\" /> van con la letra “B”. Ahora inténtalo tú.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Muy bien! Escogiste la letra “B”. Ahora inténtalo tú.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Ahora, inténtalo tú!</prosody></voice></speak>"
						}
					]
				}
			}
		]
	},
	syllableIdentification: {
		numTrainingItemRetries: 1,
		trainingItemIds: [
			2198,
			2199
		],
		items: [
			{
				itemId: 2198,
				itemLabel: "CLO-SYID-T1",
				itemType: "syllableIdentification",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"da",
					"ge",
					"du",
					"ri",
					"de"
				],
				correctAnswer: "de",
				narration: {
					instructions: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Mira las siguientes sílabas. Yo voy a decir una sílaba, y quiero que tu escojas la sílaba que hace ese sonido.</prosody></voice></speak>",
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba. de. como en dedo. de</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Escogiste la sílaba, de</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. Debiste escoger la sílaba, de, la que tiene la letra \"d\" y la letra \"e\". Intentémoslo otra vez.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Escogiste la sílaba, de</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Intentemos otro.</prosody></voice></speak>"
						}
					]
				}
			},
			{
				itemId: 2199,
				itemLabel: "CLO-SYID-T2",
				itemType: "syllableIdentification",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"la",
					"li",
					"ba",
					"lo",
					"pe"
				],
				correctAnswer: "la",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba, la. como en lata. la</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Escogiste la sílaba, la. Ahora inténtalo tú.</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. Debiste escoger la sílaba, la. la que tiene la letra \"l\" y la letra \"a\". Ahora inténtalo tú.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Escogiste la sílaba, la. Ahora inténtalo tú.</prosody></voice></speak>"
						}
					]
				}
			},
			{
				itemId: 2101,
				itemLabel: "CLO-SYID-1",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ba",
				choices: [
					"ba",
					"be",
					"ga",
					"bo",
					"fo"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ba\"> ba</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />bata.</prosody></voice></speak>"
				},
				difficulty: -1.062
			},
			{
				itemId: 2102,
				itemLabel: "CLO-SYID-2",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ra",
				choices: [
					"ra",
					"ro",
					"ta",
					"ru",
					"no"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ra\"> ra</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />rata.</prosody></voice></speak>"
				},
				difficulty: -0.825
			},
			{
				itemId: 2103,
				itemLabel: "CLO-SYID-3",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "te",
				choices: [
					"te",
					"tu",
					"ne",
					"ti",
					"ga"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"te\"> te</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />tela.</prosody></voice></speak>"
				},
				difficulty: -1.071
			},
			{
				itemId: 2104,
				itemLabel: "CLO-SYID-4",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ta",
				choices: [
					"ta",
					"to",
					"sa",
					"te",
					"po"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ta\"> ta</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />taco.</prosody></voice></speak>"
				},
				difficulty: -1.089
			},
			{
				itemId: 2105,
				itemLabel: "CLO-SYID-5",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "do",
				choices: [
					"do",
					"de",
					"mo",
					"di",
					"cu"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"do\"> do</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />domingo.</prosody></voice></speak>"
				},
				difficulty: -1.193
			},
			{
				itemId: 2106,
				itemLabel: "CLO-SYID-6",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "re",
				choices: [
					"re",
					"ri",
					"fe",
					"ra",
					"li"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"re\"> re</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />reto.</prosody></voice></speak>"
				},
				difficulty: -0.715
			},
			{
				itemId: 2107,
				itemLabel: "CLO-SYID-7",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "co",
				choices: [
					"co",
					"ca",
					"lo",
					"ci",
					"do"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ko\"> ko</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />coche.</prosody></voice></speak>"
				},
				difficulty: -1.246
			},
			{
				itemId: 2108,
				itemLabel: "CLO-SYID-8",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "to",
				choices: [
					"to",
					"de",
					"so",
					"du",
					"jo"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"to\"> to</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />torre.</prosody></voice></speak>"
				},
				difficulty: -0.852
			},
			{
				itemId: 2109,
				itemLabel: "CLO-SYID-9",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "lo",
				choices: [
					"lo",
					"lu",
					"ko",
					"la",
					"to"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"lo\"> lo</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />loco.</prosody></voice></speak>"
				},
				difficulty: -1.075
			},
			{
				itemId: 21010,
				itemLabel: "CLO-SYID-10",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "va",
				choices: [
					"va",
					"vo",
					"pa",
					"ve",
					"da"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"va\"> va</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />vaso.</prosody></voice></speak>"
				},
				difficulty: -0.119
			},
			{
				itemId: 21011,
				itemLabel: "CLO-SYID-11",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "sa",
				choices: [
					"sa",
					"se",
					"da",
					"si",
					"ra"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"sa\"> sa</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />sapo.</prosody></voice></speak>"
				},
				difficulty: -1.109
			},
			{
				itemId: 21012,
				itemLabel: "CLO-SYID-12",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ni",
				choices: [
					"ni",
					"no",
					"ri",
					"na",
					"ño"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ni\"> ni</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />niño.</prosody></voice></speak>"
				},
				difficulty: -0.564
			},
			{
				itemId: 21014,
				itemLabel: "CLO-SYID-14",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ci",
				choices: [
					"ci",
					"ca",
					"pi",
					"ce",
					"se"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"si\"> si</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />cita.</prosody></voice></speak>"
				},
				difficulty: -0.428
			},
			{
				itemId: 21013,
				itemLabel: "CLO-SYID-13",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ju",
				choices: [
					"ju",
					"ji",
					"mu",
					"ja",
					"tu"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"xu\"> x</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />jugo.</prosody></voice></speak>"
				},
				difficulty: -0.994
			},
			{
				itemId: 21015,
				itemLabel: "CLO-SYID-15",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "me",
				choices: [
					"me",
					"ma",
					"de",
					"mu",
					"gu"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"me\"> me</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />mesa.</prosody></voice></speak>"
				},
				difficulty: -1.023
			},
			{
				itemId: 21016,
				itemLabel: "CLO-SYID-16",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "na",
				choices: [
					"na",
					"ni",
					"di",
					"ne",
					"do"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"na\"> na</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />nada.</prosody></voice></speak>"
				},
				difficulty: -1.044
			},
			{
				itemId: 21017,
				itemLabel: "CLO-SYID-17",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "no",
				choices: [
					"no",
					"nu",
					"bo",
					"na",
					"he"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"no\"> no</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />nota.</prosody></voice></speak>"
				},
				difficulty: -1.296
			},
			{
				itemId: 21018,
				itemLabel: "CLO-SYID-18",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "pa",
				choices: [
					"pa",
					"pi",
					"ma",
					"pe",
					"ru"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"pa\"> pa</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />pato.</prosody></voice></speak>"
				},
				difficulty: -0.891
			},
			{
				itemId: 21019,
				itemLabel: "CLO-SYID-19",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ro",
				choices: [
					"ro",
					"re",
					"fo",
					"ri",
					"ba"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ro\"> ro</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />rojo.</prosody></voice></speak>"
				},
				difficulty: -0.985
			},
			{
				itemId: 21020,
				itemLabel: "CLO-SYID-20",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "se",
				choices: [
					"se",
					"sa",
					"de",
					"so",
					"li"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"se\"> se</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />seco.</prosody></voice></speak>"
				},
				difficulty: -1.105
			},
			{
				itemId: 21021,
				itemLabel: "CLO-SYID-21",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "be",
				choices: [
					"be",
					"ba",
					"ce",
					"bu",
					"ro"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"be\"> be</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />bebé.</prosody></voice></speak>"
				},
				difficulty: -1.092
			},
			{
				itemId: 21022,
				itemLabel: "CLO-SYID-22",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "bi",
				choices: [
					"bi",
					"bu",
					"si",
					"bo",
					"di"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"bi\"> bi</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />bici.</prosody></voice></speak>"
				},
				difficulty: -0.522
			},
			{
				itemId: 21023,
				itemLabel: "CLO-SYID-23",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "bo",
				choices: [
					"bo",
					"be",
					"to",
					"ba",
					"do"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"bo\"> bo</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />bota.</prosody></voice></speak>"
				},
				difficulty: -0.848
			},
			{
				itemId: 21024,
				itemLabel: "CLO-SYID-24",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "bu",
				choices: [
					"bu",
					"bi",
					"cu",
					"be",
					"du"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"bu\"> bu</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />burro.</prosody></voice></speak>"
				},
				difficulty: -0.715
			},
			{
				itemId: 21025,
				itemLabel: "CLO-SYID-25",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ve",
				choices: [
					"ve",
					"bu",
					"de",
					"vu",
					"no"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ve\"> ve</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />vela.</prosody></voice></speak>"
				},
				difficulty: 0.004
			},
			{
				itemId: 21026,
				itemLabel: "CLO-SYID-26",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "fo",
				choices: [
					"fo",
					"fa",
					"jo",
					"fi",
					"to"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"fo\"> fo</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />foto.</prosody></voice></speak>"
				},
				difficulty: -1.067
			},
			{
				itemId: 21027,
				itemLabel: "CLO-SYID-27",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "fi",
				choices: [
					"fi",
					"fu",
					"pi",
					"fe",
					"ti"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"fi\"> fi</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />fila.</prosody></voice></speak>"
				},
				difficulty: -0.575
			},
			{
				itemId: 21028,
				itemLabel: "CLO-SYID-28",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ri",
				choices: [
					"ri",
					"re",
					"hi",
					"ro",
					"bi"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ri\"> ri</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />rico.</prosody></voice></speak>"
				},
				difficulty: -0.458
			},
			{
				itemId: 21029,
				itemLabel: "CLO-SYID-29",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "bas",
				choices: [
					"bas",
					"bos",
					"fas",
					"bes",
					"mes"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"bas\"> bas</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />basta.</prosody></voice></speak>"
				},
				difficulty: -0.829
			},
			{
				itemId: 21030,
				itemLabel: "CLO-SYID-30",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "can",
				choices: [
					"can",
					"cin",
					"ran",
					"con",
					"fin"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"kann\"> kan</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />cantar.</prosody></voice></speak>"
				},
				difficulty: -0.904
			},
			{
				itemId: 21031,
				itemLabel: "CLO-SYID-31",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ren",
				choices: [
					"ren",
					"run",
					"sen",
					"ron",
					"man"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ren\"> ren</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />rentar.</prosody></voice></speak>"
				},
				difficulty: -0.892
			},
			{
				itemId: 21032,
				itemLabel: "CLO-SYID-32",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "men",
				choices: [
					"men",
					"min",
					"pen",
					"mon",
					"gan"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"men\"> men</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />mente.</prosody></voice></speak>"
				},
				difficulty: -0.812
			},
			{
				itemId: 21033,
				itemLabel: "CLO-SYID-33",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "par",
				choices: [
					"par",
					"por",
					"jar",
					"per",
					"nor"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"par\"> par</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />parte.</prosody></voice></speak>"
				},
				difficulty: -0.778
			},
			{
				itemId: 21034,
				itemLabel: "CLO-SYID-34",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ver",
				choices: [
					"ver",
					"vir",
					"ker",
					"vor",
					"tir"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ver\"> ver</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />verde.</prosody></voice></speak>"
				},
				difficulty: -0.646
			},
			{
				itemId: 21035,
				itemLabel: "CLO-SYID-35",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "por",
				choices: [
					"por",
					"pir",
					"dor",
					"per",
					"sar"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"por\"> por</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />porqué.</prosody></voice></speak>"
				},
				difficulty: -0.888
			},
			{
				itemId: 21036,
				itemLabel: "CLO-SYID-36",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "las",
				choices: [
					"las",
					"los",
					"has",
					"lis",
					"bis"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"las\"> las</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />lastimar.</prosody></voice></speak>"
				},
				difficulty: -0.91
			},
			{
				itemId: 21037,
				itemLabel: "CLO-SYID-37",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "es",
				choices: [
					"es",
					"is",
					"ed",
					"as",
					"ef"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"es\"> es</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />estar.</prosody></voice></speak>"
				},
				difficulty: -0.975
			},
			{
				itemId: 21038,
				itemLabel: "CLO-SYID-38",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "en",
				choices: [
					"en",
					"an",
					"et",
					"in",
					"ow"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"en\"> en</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />entrar.</prosody></voice></speak>"
				},
				difficulty: -0.728
			},
			{
				itemId: 21039,
				itemLabel: "CLO-SYID-39",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "an",
				choices: [
					"an",
					"en",
					"ag",
					"un",
					"am"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"an\"> an</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />andar.</prosody></voice></speak>"
				},
				difficulty: -0.785
			},
			{
				itemId: 21040,
				itemLabel: "CLO-SYID-40",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "al",
				choices: [
					"al",
					"el",
					"as",
					"ol",
					"at"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"al\"> al</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />alfabeto.</prosody></voice></speak>"
				},
				difficulty: -0.67
			},
			{
				itemId: 21041,
				itemLabel: "CLO-SYID-41",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "es",
				choices: [
					"es",
					"as",
					"et",
					"us",
					"er"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"es\"> es</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />escoba.</prosody></voice></speak>"
				},
				difficulty: -1.103
			},
			{
				itemId: 21042,
				itemLabel: "CLO-SYID-42",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "ar",
				choices: [
					"ar",
					"er",
					"at",
					"id",
					"ak"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"ar\"> ar</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />árbol.</prosody></voice></speak>"
				},
				difficulty: -0.999
			},
			{
				itemId: 21043,
				itemLabel: "CLO-SYID-43",
				itemType: "syllableIdentification",
				itemClass: "active",
				correctAnswer: "as",
				choices: [
					"as",
					"us",
					"al",
					"os",
					"af"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la sílaba que dice<break time=\"100ms\" /> <prosody volume=\"+100.00%\"><phoneme alphabet=\"ipa\" ph=\"as\"> as</phoneme> </prosody><break time=\"250ms\" />  como en <break time=\"50ms\" />astronauta.</prosody></voice></speak>"
				},
				difficulty: -0.833
			}
		]
	},
	wordRecognition: {
		numTrainingItemRetries: 1,
		trainingItemIds: [
			4098,
			4099
		],
		items: [
			{
				itemId: 4098,
				itemLabel: "CLO-WR-T1",
				itemType: "wordRecognition",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"bote",
					"bola",
					"moto",
					"dedo",
					"beber",
					"pofe",
					"bota"
				],
				correctAnswer: "bota",
				narration: {
					instructions: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Mira las siguientes palabras. Yo voy a decir una palabra, y quiero que tú escojas esa palabra. Voy a repetir la palabra una vez. ¡Vamos a probar!</prosody></voice></speak>",
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra \"bota\". \"bota\".</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Escogiste la palabra \"bota\".</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. Debiste escoger la palabra “bota”, la que se deletrea, ve, \"o\", \"te\", \"a\". Intentémoslo otra vez.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Escogiste la palabra \"bota\".</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Intentemos otro.</prosody></voice></speak>"
						}
					]
				}
			},
			{
				itemId: 4099,
				itemLabel: "CLO-WR-T2",
				itemType: "wordRecognition",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"tela",
					"tele",
					"tema",
					"temo",
					"jugo",
					"tapón",
					"fibe"
				],
				correctAnswer: "tela",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra \"tela\". \"tela\".</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Escogiste la palabra \"tela\". Ahora inténtalo tú!</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. Debiste escoger la palabra \"tela\". la primera palabra. Intentémoslo otra vez!</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. Debiste escoger la palabra “tela”, la que se deletrea, \"te\", \"e\", \"ele\", \"a\". Intentémoslo otra vez.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Intentemos otro.</prosody></voice></speak>"
						}
					]
				}
			},
			{
				itemId: 4001,
				itemLabel: "CLO-WR-1",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "mesa",
				choices: [
					"mesa",
					"misa",
					"meta",
					"menú",
					"nido",
					"mochila",
					"nelo"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">mesa <break time=\"750ms\" /> mesa </prosody></prosody></voice></speak>"
				},
				difficulty: -0.743
			},
			{
				itemId: 4002,
				itemLabel: "CLO-WR-2",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "cosa",
				choices: [
					"cosa",
					"cose",
					"cola",
					"codo",
					"soda",
					"cursi",
					"suna"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">cosa <break time=\"750ms\" /> cosa </prosody></prosody></voice></speak>"
				},
				difficulty: -0.852
			},
			{
				itemId: 4003,
				itemLabel: "CLO-WR-3",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "taza",
				choices: [
					"taza",
					"tazo",
					"talla",
					"taco",
					"lazo",
					"tipos",
					"fule"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">taza <break time=\"750ms\" /> taza </prosody></prosody></voice></speak>"
				},
				difficulty: -0.65
			},
			{
				itemId: 4004,
				itemLabel: "CLO-WR-4",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "moto",
				choices: [
					"moto",
					"mato",
					"moco",
					"malo",
					"nota",
					"monja",
					"nuna"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">moto <break time=\"750ms\" /> moto </prosody></prosody></voice></speak>"
				},
				difficulty: -0.723
			},
			{
				itemId: 4005,
				itemLabel: "CLO-WR-5",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "besa",
				choices: [
					"besa",
					"beso",
					"beba",
					"bota",
					"pesa",
					"balón",
					"duza"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">besa <break time=\"750ms\" /> besa </prosody></prosody></voice></speak>"
				},
				difficulty: -0.332
			},
			{
				itemId: 4006,
				itemLabel: "CLO-WR-6",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "palo",
				choices: [
					"palo",
					"pala",
					"pato",
					"papa",
					"bala",
					"peces",
					"biga"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">palo <break time=\"750ms\" /> palo </prosody></prosody></voice></speak>"
				},
				difficulty: -0.692
			},
			{
				itemId: 4007,
				itemLabel: "CLO-WR-7",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "dedo",
				choices: [
					"dedo",
					"dudo",
					"debo",
					"nido",
					"beso",
					"danza",
					"pupo"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">dedo <break time=\"750ms\" /> dedo </prosody></prosody></voice></speak>"
				},
				difficulty: -0.424
			},
			{
				itemId: 4008,
				itemLabel: "CLO-WR-8",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "mamá",
				choices: [
					"mamá",
					"mami",
					"mala",
					"lima",
					"nata",
					"micro",
					"nate"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">mamá <break time=\"750ms\" /> mamá </prosody></prosody></voice></speak>"
				},
				difficulty: -1.138
			},
			{
				itemId: 4009,
				itemLabel: "CLO-WR-9",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "papa",
				choices: [
					"papa",
					"papi",
					"pala",
					"palo",
					"bata",
					"pinza",
					"bula"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">papa <break time=\"750ms\" /> papa </prosody></prosody></voice></speak>"
				},
				difficulty: -0.56
			},
			{
				itemId: 40010,
				itemLabel: "CLO-WR-10",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "mala",
				choices: [
					"mala",
					"malo",
					"mapa",
					"mano",
					"wepa",
					"mezcla",
					"nera"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">mala <break time=\"750ms\" /> mala </prosody></prosody></voice></speak>"
				},
				difficulty: -0.832
			},
			{
				itemId: 40011,
				itemLabel: "CLO-WR-11",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "pega",
				choices: [
					"pega",
					"paga",
					"pela",
					"pelo",
					"baja",
					"pulsar",
					"bada"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">pega <break time=\"750ms\" /> pega </prosody></prosody></voice></speak>"
				},
				difficulty: -0.183
			},
			{
				itemId: 40012,
				itemLabel: "CLO-WR-12",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "seco",
				choices: [
					"seco",
					"saco",
					"sedo",
					"sepa",
					"celo",
					"simple",
					"cema"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">seco <break time=\"750ms\" /> seco </prosody></prosody></voice></speak>"
				},
				difficulty: -0.69
			},
			{
				itemId: 40013,
				itemLabel: "CLO-WR-13",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "meto",
				choices: [
					"meto",
					"mato",
					"mero",
					"mano",
					"nota",
					"mapas",
					"nite"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">meto <break time=\"750ms\" /> meto </prosody></prosody></voice></speak>"
				},
				difficulty: -0.7
			},
			{
				itemId: 40014,
				itemLabel: "CLO-WR-14",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "modo",
				choices: [
					"modo",
					"mido",
					"mono",
					"meto",
					"nido",
					"morder",
					"nipo"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">modo <break time=\"750ms\" /> modo </prosody></prosody></voice></speak>"
				},
				difficulty: -0.302
			},
			{
				itemId: 40015,
				itemLabel: "CLO-WR-15",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "casa",
				choices: [
					"casa",
					"casi",
					"cada",
					"cena",
					"eras",
					"cerdo",
					"sato"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">casa <break time=\"750ms\" /> casa </prosody></prosody></voice></speak>"
				},
				difficulty: -0.813
			},
			{
				itemId: 40016,
				itemLabel: "CLO-WR-16",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "sano",
				choices: [
					"sano",
					"sino",
					"saco",
					"gane",
					"casa",
					"sellos",
					"cema"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">sano <break time=\"750ms\" /> sano </prosody></prosody></voice></speak>"
				},
				difficulty: -0.648
			},
			{
				itemId: 40017,
				itemLabel: "CLO-WR-17",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "luna",
				choices: [
					"luna",
					"lona",
					"lupa",
					"lujo",
					"tusa",
					"llama",
					"tine"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">luna <break time=\"750ms\" /> luna </prosody></prosody></voice></speak>"
				},
				difficulty: -0.538
			},
			{
				itemId: 40018,
				itemLabel: "CLO-WR-18",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "abre",
				choices: [
					"abre",
					"abro",
					"arte",
					"arma",
					"otro",
					"abeja",
					"emur"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">abre <break time=\"750ms\" /> abre </prosody></prosody></voice></speak>"
				},
				difficulty: -0.386
			},
			{
				itemId: 40019,
				itemLabel: "CLO-WR-19",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "muera",
				choices: [
					"muera",
					"muere",
					"muela",
					"muele",
					"nuevo",
					"miento",
					"morte"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">muera <break time=\"750ms\" /> muera </prosody></prosody></voice></speak>"
				},
				difficulty: 0.079
			},
			{
				itemId: 40020,
				itemLabel: "CLO-WR-20",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "nueva",
				choices: [
					"nueva",
					"nueve",
					"nuera",
					"nieta",
					"mosca",
					"nido",
					"nuepo"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">nueva <break time=\"750ms\" /> nueva </prosody></prosody></voice></speak>"
				},
				difficulty: -0.314
			},
			{
				itemId: 40021,
				itemLabel: "CLO-WR-21",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "árbol",
				choices: [
					"árbol",
					"abrir",
					"árbor",
					"argel",
					"oveja",
					"arcoíris",
					"arton"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">árbol <break time=\"750ms\" /> árbol </prosody></prosody></voice></speak>"
				},
				difficulty: 0.012
			},
			{
				itemId: 40022,
				itemLabel: "CLO-WR-22",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "quejo",
				choices: [
					"quejo",
					"queja",
					"queso",
					"quede",
					"puedo",
					"químico",
					"quino"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">quejo <break time=\"750ms\" /> quejo </prosody></prosody></voice></speak>"
				},
				difficulty: -0.466
			},
			{
				itemId: 40023,
				itemLabel: "CLO-WR-23",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "quita",
				choices: [
					"quita",
					"quite",
					"quizá",
					"queda",
					"puede",
					"quejo",
					"quane"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">quita <break time=\"750ms\" /> quita </prosody></prosody></voice></speak>"
				},
				difficulty: -0.452
			},
			{
				itemId: 40024,
				itemLabel: "CLO-WR-24",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "pasto",
				choices: [
					"pasto",
					"pasta",
					"parto",
					"hasta",
					"basta",
					"pupusa",
					"parle"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">pasto <break time=\"750ms\" /> pasto </prosody></prosody></voice></speak>"
				},
				difficulty: -0.422
			},
			{
				itemId: 40025,
				itemLabel: "CLO-WR-25",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "calma",
				choices: [
					"calma",
					"calmo",
					"calda",
					"caldo",
					"salta",
					"cuaderno",
					"cenra"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">calma <break time=\"750ms\" /> calma </prosody></prosody></voice></speak>"
				},
				difficulty: -0.566
			},
			{
				itemId: 40026,
				itemLabel: "CLO-WR-26",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "fuego",
				choices: [
					"fuego",
					"fugar",
					"fuero",
					"juega",
					"tengo",
					"fuerte",
					"fueso"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">fuego <break time=\"750ms\" /> fuego </prosody></prosody></voice></speak>"
				},
				difficulty: -0.41
			},
			{
				itemId: 40027,
				itemLabel: "CLO-WR-27",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "huevo",
				choices: [
					"huevo",
					"hubo",
					"hueco",
					"cueva",
					"nuevo",
					"higuera",
					"huepa"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">huevo <break time=\"750ms\" /> huevo </prosody></prosody></voice></speak>"
				},
				difficulty: -0.234
			},
			{
				itemId: 40028,
				itemLabel: "CLO-WR-28",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "cuero",
				choices: [
					"cuero",
					"cuenta",
					"cuerno",
					"cueva",
					"suero",
					"churro",
					"cuara"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">cuero <break time=\"750ms\" /> cuero </prosody></prosody></voice></speak>"
				},
				difficulty: -0.208
			},
			{
				itemId: 40029,
				itemLabel: "CLO-WR-29",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "libro",
				choices: [
					"libro",
					"libre",
					"litro",
					"lider",
					"imitar",
					"león",
					"lispo"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">libro <break time=\"750ms\" /> libro </prosody></prosody></voice></speak>"
				},
				difficulty: -0.504
			},
			{
				itemId: 40030,
				itemLabel: "CLO-WR-30",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "mando",
				choices: [
					"mando",
					"mundo",
					"manso",
					"marca",
					"nació",
					"moldes",
					"maner"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">mando <break time=\"750ms\" /> mando </prosody></prosody></voice></speak>"
				},
				difficulty: -0.539
			},
			{
				itemId: 40031,
				itemLabel: "CLO-WR-31",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "corre",
				choices: [
					"corre",
					"corrí",
					"corte",
					"corta",
					"error",
					"cartera",
					"ceste"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">corre <break time=\"750ms\" /> corre </prosody></prosody></voice></speak>"
				},
				difficulty: -0.583
			},
			{
				itemId: 40032,
				itemLabel: "CLO-WR-32",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "perro",
				choices: [
					"perro",
					"porra",
					"pelo",
					"perdi",
					"barro",
					"pantalón",
					"pirra"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">perro <break time=\"750ms\" /> perro </prosody></prosody></voice></speak>"
				},
				difficulty: -0.661
			},
			{
				itemId: 40033,
				itemLabel: "CLO-WR-33",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "hueco",
				choices: [
					"hueco",
					"hueca",
					"huevo",
					"huele",
					"muelo",
					"hubiera",
					"huema"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">hueco <break time=\"750ms\" /> hueco </prosody></prosody></voice></speak>"
				},
				difficulty: -0.355
			},
			{
				itemId: 40034,
				itemLabel: "CLO-WR-34",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "nieta",
				choices: [
					"nieta",
					"nieto",
					"nieva",
					"nieve",
					"miedo",
					"nuestra",
					"girer"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">nieta <break time=\"750ms\" /> nieta </prosody></prosody></voice></speak>"
				},
				difficulty: -0.538
			},
			{
				itemId: 40035,
				itemLabel: "CLO-WR-35",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "cerdo",
				choices: [
					"cerdo",
					"cerda",
					"cerca",
					"cierto",
					"estar",
					"cuerpo",
					"pilla"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">cerdo <break time=\"750ms\" /> cerdo </prosody></prosody></voice></speak>"
				},
				difficulty: -0.477
			},
			{
				itemId: 40036,
				itemLabel: "CLO-WR-36",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "falda",
				choices: [
					"falda",
					"falta",
					"falsa",
					"caldo",
					"talco",
					"felicidad",
					"fuldo"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">falda <break time=\"750ms\" /> falda </prosody></prosody></voice></speak>"
				},
				difficulty: -0.01
			},
			{
				itemId: 40037,
				itemLabel: "CLO-WR-37",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "causa",
				choices: [
					"causa",
					"causó",
					"cauta",
					"jaula",
					"onces",
					"churro",
					"caola"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">causa <break time=\"750ms\" /> causa </prosody></prosody></voice></speak>"
				},
				difficulty: -0.493
			},
			{
				itemId: 40038,
				itemLabel: "CLO-WR-38",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "blusa",
				choices: [
					"blusa",
					"bluso",
					"brisa",
					"bleda",
					"dedos",
					"botella",
					"brame"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">blusa <break time=\"750ms\" /> blusa </prosody></prosody></voice></speak>"
				},
				difficulty: -0.448
			},
			{
				itemId: 40039,
				itemLabel: "CLO-WR-39",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "crema",
				choices: [
					"crema",
					"croma",
					"creta",
					"cruda",
					"educa",
					"copa",
					"crina"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">crema <break time=\"750ms\" /> crema </prosody></prosody></voice></speak>"
				},
				difficulty: -0.569
			},
			{
				itemId: 40040,
				itemLabel: "CLO-WR-40",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "brisa",
				choices: [
					"brisa",
					"brasa",
					"brida",
					"broma",
					"droga",
					"bosque",
					"brofe"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">brisa <break time=\"750ms\" /> brisa </prosody></prosody></voice></speak>"
				},
				difficulty: -0.495
			},
			{
				itemId: 40041,
				itemLabel: "CLO-WR-41",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "broma",
				choices: [
					"broma",
					"bruma",
					"brota",
					"blusa",
					"damas",
					"bici",
					"bruna"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">broma <break time=\"750ms\" /> broma </prosody></prosody></voice></speak>"
				},
				difficulty: -0.227
			},
			{
				itemId: 40042,
				itemLabel: "CLO-WR-42",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "creer",
				choices: [
					"creer",
					"crear",
					"crees",
					"crias",
					"ejote",
					"completar",
					"crone"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">creer <break time=\"750ms\" /> creer </prosody></prosody></voice></speak>"
				},
				difficulty: -0.025
			},
			{
				itemId: 40043,
				itemLabel: "CLO-WR-43",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "playa",
				choices: [
					"playa",
					"plega",
					"plata",
					"ploma",
					"quita",
					"pregunta",
					"pluno"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">playa <break time=\"750ms\" /> playa </prosody></prosody></voice></speak>"
				},
				difficulty: -0.569
			},
			{
				itemId: 40044,
				itemLabel: "CLO-WR-44",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "escoba",
				choices: [
					"escoba",
					"escoge",
					"escapa",
					"escusa",
					"camino",
					"estudiante",
					"estona"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">escoba <break time=\"750ms\" /> escoba </prosody></prosody></voice></speak>"
				},
				difficulty: -0.433
			},
			{
				itemId: 40045,
				itemLabel: "CLO-WR-45",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "enojar",
				choices: [
					"enojar",
					"entrar",
					"educar",
					"enviar",
					"cantar",
					"entrada",
					"empima"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">enojar <break time=\"750ms\" /> enojar </prosody></prosody></voice></speak>"
				},
				difficulty: -0.509
			},
			{
				itemId: 40046,
				itemLabel: "CLO-WR-46",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "misterio",
				choices: [
					"misterio",
					"maestro",
					"mismos",
					"ministro",
					"naranjas",
					"miércoles",
					"mostenar"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">misterio <break time=\"750ms\" /> misterio </prosody></prosody></voice></speak>"
				},
				difficulty: -0.211
			},
			{
				itemId: 40047,
				itemLabel: "CLO-WR-47",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "sandía",
				choices: [
					"sandía",
					"sangre",
					"sabría",
					"salario",
					"zapato",
					"salud",
					"selamo"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">sandía <break time=\"750ms\" /> sandía </prosody></prosody></voice></speak>"
				},
				difficulty: -0.554
			},
			{
				itemId: 4048,
				itemLabel: "CLO-WR-48",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "temblor",
				choices: [
					"temblor",
					"temblar",
					"tenedor",
					"técnica",
					"terando"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">temblor <break time=\"750ms\" /> temblor </prosody></prosody></voice></speak>"
				},
				difficulty: -0.16
			},
			{
				itemId: 4049,
				itemLabel: "CLO-WR-49",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "descuidado",
				choices: [
					"descuidado",
					"descuidada",
					"defraudado",
					"desbordado",
					"decierdote"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">descuidado <break time=\"750ms\" /> descuidado </prosody></prosody></voice></speak>"
				},
				difficulty: -0.127
			},
			{
				itemId: 4050,
				itemLabel: "CLO-WR-50",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "persuadir",
				choices: [
					"persuadir",
					"presentir",
					"perseguir",
					"proseguir",
					"permiento"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">persuadir <break time=\"750ms\" /> persuadir </prosody></prosody></voice></speak>"
				},
				difficulty: 0.212
			},
			{
				itemId: 4051,
				itemLabel: "CLO-WR-51",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "inteligente",
				choices: [
					"inteligente",
					"inteligencia",
					"ingrediente",
					"ineficiente",
					"intrasterosa"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">inteligente <break time=\"750ms\" /> inteligente </prosody></prosody></voice></speak>"
				},
				difficulty: -0.331
			},
			{
				itemId: 4052,
				itemLabel: "CLO-WR-52",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "decepcionado",
				choices: [
					"decepcionado",
					"decepcionada",
					"desabotonado",
					"dimensionado",
					"defertaidado"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">desepcionado <break time=\"750ms\" /> desepcionado </prosody></prosody></voice></speak>"
				},
				difficulty: 0.308
			},
			{
				itemId: 4053,
				itemLabel: "CLO-WR-53",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "distribuir",
				choices: [
					"distribuir",
					"distribuye",
					"distinguir",
					"deshinibir",
					"difromator"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">distribuir <break time=\"750ms\" /> distribuir </prosody></prosody></voice></speak>"
				},
				difficulty: 0.609
			},
			{
				itemId: 4054,
				itemLabel: "CLO-WR-54",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "simplificar",
				choices: [
					"simplificar",
					"simplificó",
					"simplificado",
					"simultáneo",
					"sientraster"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">simplificar <break time=\"750ms\" /> simplificar </prosody></prosody></voice></speak>"
				},
				difficulty: -0.247
			},
			{
				itemId: 4055,
				itemLabel: "CLO-WR-55",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "exagerar",
				choices: [
					"exagerar",
					"exageró",
					"exagerado",
					"exageración",
					"exigrado"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">exagerar <break time=\"750ms\" /> exagerar </prosody></prosody></voice></speak>"
				},
				difficulty: -0.093
			},
			{
				itemId: 4056,
				itemLabel: "CLO-WR-56",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "desarrollar",
				choices: [
					"desarrollar",
					"desarrollo",
					"descogollar",
					"descabellar",
					"destroblar"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">desarrollar <break time=\"750ms\" /> desarrollar </prosody></prosody></voice></speak>"
				},
				difficulty: -0.08
			},
			{
				itemId: 4057,
				itemLabel: "CLO-WR-57",
				itemType: "wordRecognition",
				itemClass: "active",
				correctAnswer: "pegajoso",
				choices: [
					"pegajoso",
					"pegajosa",
					"pegarlos",
					"perezoso",
					"petimoso"
				],
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge la palabra<break time=\"100ms\" /> <prosody volume=\"+100.00%\">pegajoso <break time=\"750ms\" /> pegajoso </prosody></prosody></voice></speak>"
				},
				difficulty: -0.348
			}
		]
	},
	words2Sentence: {
		numTrainingItemRetries: 1,
		trainingItemIds: [
			6098,
			6099
		],
		items: [
			{
				itemId: 6098,
				itemLabel: "CLO-W2S-T1",
				itemType: "words2Sentence",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"jugo.",
					"mi",
					"Tomo"
				],
				correctAnswer: "Tomo mi jugo.",
				narration: {
					instructions: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Mira las siguientes palabras en la parte izquierda de la página. Estas palabras pueden ser reorganizadas para formar una oración. Puedes buscar pistas, como mayúsculas y puntuación que te ayudan a poner las palabras en la oración donde deben ir. ¡Vamos a probar! Escoge y arrastra las palabras a las líneas de escritura en el lado derecho de la pantalla y reorganízalas para que formen una oración. La barrita verde te muestra dónde van a ir tus palabras.</prosody></voice></speak>",
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Ahora, escribe una oración usando todas las palabras. ¡Te doy una pista! Las dos primeras palabras son, \"Tomo, mi\". \"Tomo, mi\".</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Hiciste una oración usando todas las palabras. ¿Viste la barrita verde? La barrita verde te muestra donde van a ir tus palabras.</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. La palabra \"tomo\" debería ir primero porque es la única mayúscula. luego la palabra \"mi\", y la palabra \"jugo\" va al final porque tiene un punto después de la palabra. ¿Viste la barrita verde? La barrita verde te muestra donde van a ir tus palabras. Mira las siguientes palabras en la parte izquierda de la página. Estas palabras pueden ser reorganizadas para formar una oración. Puedes buscar pistas, como mayúsculas y puntuación que te ayudan a poner las palabras en la oración donde deben ir. ¡Vamos a probar! Escoge y arrastra las palabras a las líneas de escritura en el lado derecho de la pantalla y reorganízalas, para que formen una oración. La barrita verde te muestra dónde van a ir tus palabras.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">¡Muy bien! Hiciste una oración usando todas las palabras. ¿Viste la barrita verde? La barrita verde te muestra donde van a ir tus palabras.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Intentemos otro.</prosody></voice></speak>"
						}
					]
				}
			},
			{
				itemId: 6099,
				itemLabel: "CLO-W2S-T2",
				itemType: "words2Sentence",
				itemClass: "training",
				difficulty: 0,
				choices: [
					"voy",
					"parque.",
					"al",
					"Yo"
				],
				correctAnswer: "Yo voy al parque.",
				narration: {
					prompt: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Escoge y arrastra las palabras a las líneas de escritura en el lado derecho de la pantalla y reorganízalas para que formen una oración. Vas a hacer la oración tú, asegurándote que usas todas las palabras. Esta vez yo me quedo callado.</prosody></voice></speak>",
					feedback: [
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Muy bien, usaste todas las palabras para formar la oración \"Yo voy al parque\". Ahora inténtalo tú.</prosody></voice></speak>"
						},
						{
							tryCount: 1,
							retriesRemaining: 1,
							correct: false,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">No es correcto. La oración debería decir \"Yo voy al parque\". La oración tiene sentido y hay pistas. La palabra \"yo\" va primero porque tiene mayúscula, luego va la palabra \"voy\", luego la palabra \"al\" y la palabra \"parque\" va al final porque tiene un punto después de la palabra. Ahora inténtalo tú.</prosody></voice></speak>"
						},
						{
							tryCount: 2,
							retriesRemaining: 0,
							correct: true,
							feedback: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-US\"><voice name=\"es-US-AlonsoNeural\"><prosody rate=\"0%\" pitch=\"0%\">Muy bien, usaste todas las palabras para formar la oración \"Yo voy al parque\". Ahora inténtalo tú.</prosody></voice></speak>"
						}
					]
				}
			},
			{
				itemId: 6001,
				itemLabel: "CLO-W2S-1",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.135,
				correctAnswer: "Marco tiene siete años.",
				choices: [
					"Marco",
					"tiene",
					"siete",
					"años."
				]
			},
			{
				itemId: 6002,
				itemLabel: "CLO-W2S-2",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: -0.226,
				correctAnswer: "Me gusta el chocolate.",
				choices: [
					"Me",
					"gusta",
					"el",
					"chocolate."
				]
			},
			{
				itemId: 6003,
				itemLabel: "CLO-W2S-3",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.118,
				correctAnswer: "¿Quieres comer un dulce?",
				choices: [
					"¿Quieres",
					"comer",
					"un",
					"dulce?"
				]
			},
			{
				itemId: 6004,
				itemLabel: "CLO-W2S-4",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: -0.101,
				correctAnswer: "Yo brinco muy alto.",
				choices: [
					"Yo",
					"brinco",
					"muy",
					"alto."
				]
			},
			{
				itemId: 6005,
				itemLabel: "CLO-W2S-5",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.12,
				correctAnswer: "Nosotros hacemos la tarea.",
				choices: [
					"Nosotros",
					"hacemos",
					"la",
					"tarea."
				]
			},
			{
				itemId: 6006,
				itemLabel: "CLO-W2S-6",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.387,
				correctAnswer: "Mamá pintó un cuadro.",
				choices: [
					"Mamá",
					"pintó",
					"un",
					"cuadro."
				]
			},
			{
				itemId: 6007,
				itemLabel: "CLO-W2S-7",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: -0.026,
				correctAnswer: "La rana salta mucho.",
				choices: [
					"La",
					"rana",
					"salta",
					"mucho."
				]
			},
			{
				itemId: 6008,
				itemLabel: "CLO-W2S-8",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.187,
				correctAnswer: "Juego con mi hermano.",
				choices: [
					"Juego",
					"con",
					"mi",
					"hermano."
				]
			},
			{
				itemId: 6009,
				itemLabel: "CLO-W2S-9",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.109,
				correctAnswer: "Yo andaba en patineta.",
				choices: [
					"Yo",
					"andaba",
					"en",
					"patineta."
				]
			},
			{
				itemId: 60010,
				itemLabel: "CLO-W2S-10",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.183,
				correctAnswer: "¿Querías jugar con mi pelota?",
				choices: [
					"¿Querías",
					"jugar",
					"con",
					"mi",
					"pelota?"
				]
			},
			{
				itemId: 60011,
				itemLabel: "CLO-W2S-11",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.556,
				correctAnswer: "¿Quieren conocer a mi amigo?",
				choices: [
					"¿Quieren",
					"conocer",
					"a",
					"mi",
					"amigo?"
				]
			},
			{
				itemId: 60012,
				itemLabel: "CLO-W2S-12",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.276,
				correctAnswer: "Me encanta comer paletas rojas.",
				choices: [
					"Me",
					"encanta",
					"comer",
					"paletas",
					"rojas."
				]
			},
			{
				itemId: 60013,
				itemLabel: "CLO-W2S-13",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.271,
				correctAnswer: "La niña dibujó una ballena.",
				choices: [
					"La",
					"niña",
					"dibujó",
					"una",
					"ballena."
				]
			},
			{
				itemId: 60014,
				itemLabel: "CLO-W2S-14",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.636,
				correctAnswer: "La maestra nos trajo dulces.",
				choices: [
					"La",
					"maestra",
					"nos",
					"trajo",
					"dulces."
				]
			},
			{
				itemId: 60015,
				itemLabel: "CLO-W2S-15",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.061,
				correctAnswer: "No me gusta el fútbol.",
				choices: [
					"No",
					"me",
					"gusta",
					"el",
					"fútbol."
				]
			},
			{
				itemId: 60016,
				itemLabel: "CLO-W2S-16",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.103,
				correctAnswer: "Mi hermano comió la manzana.",
				choices: [
					"Mi",
					"hermano",
					"comió",
					"la",
					"manzana."
				]
			},
			{
				itemId: 60017,
				itemLabel: "CLO-W2S-17",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.312,
				correctAnswer: "¿Te gustaría ir al cine?",
				choices: [
					"¿Te",
					"gustaría",
					"ir",
					"al",
					"cine?"
				]
			},
			{
				itemId: 60018,
				itemLabel: "CLO-W2S-18",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.292,
				correctAnswer: "Hace mucho calor hoy.",
				choices: [
					"Hace",
					"mucho",
					"calor",
					"hoy."
				]
			},
			{
				itemId: 60019,
				itemLabel: "CLO-W2S-19",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.087,
				correctAnswer: "¿Me puedes prestar un lápiz?",
				choices: [
					"¿Me",
					"puedes",
					"prestar",
					"un",
					"lápiz?"
				]
			},
			{
				itemId: 60020,
				itemLabel: "CLO-W2S-20",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.8,
				correctAnswer: "El niño y la niña se abrazaron.",
				choices: [
					"El",
					"niño",
					"y",
					"la",
					"niña",
					"se",
					"abrazaron."
				]
			},
			{
				itemId: 60021,
				itemLabel: "CLO-W2S-21",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.575,
				correctAnswer: "Carlos nunca hacía su tarea.",
				choices: [
					"Carlos",
					"nunca",
					"hacía",
					"su",
					"tarea."
				]
			},
			{
				itemId: 60022,
				itemLabel: "CLO-W2S-22",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.879,
				correctAnswer: "No quiero que te vayas.",
				choices: [
					"No",
					"quiero",
					"que",
					"te",
					"vayas."
				]
			},
			{
				itemId: 60023,
				itemLabel: "CLO-W2S-23",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.625,
				correctAnswer: "El gato no amaba al perro.",
				choices: [
					"El",
					"gato",
					"no",
					"amaba",
					"al",
					"perro."
				]
			},
			{
				itemId: 60024,
				itemLabel: "CLO-W2S-24",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.231,
				correctAnswer: "Te iba a contar un chiste.",
				choices: [
					"Te",
					"iba",
					"a",
					"contar",
					"un",
					"chiste."
				]
			},
			{
				itemId: 60025,
				itemLabel: "CLO-W2S-25",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.871,
				correctAnswer: "¿Cuál película viste en el cine?",
				choices: [
					"¿Cuál",
					"película",
					"viste",
					"en",
					"el",
					"cine?"
				]
			},
			{
				itemId: 60026,
				itemLabel: "CLO-W2S-26",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.819,
				correctAnswer: "Su mamá quiere que coman todo.",
				choices: [
					"Su",
					"mamá",
					"quiere",
					"que",
					"coman",
					"todo."
				]
			},
			{
				itemId: 60027,
				itemLabel: "CLO-W2S-27",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.69,
				correctAnswer: "La fila estuvo bastante larga.",
				choices: [
					"La",
					"fila",
					"estuvo",
					"bastante",
					"larga."
				]
			},
			{
				itemId: 60028,
				itemLabel: "CLO-W2S-28",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.019,
				correctAnswer: "No tires la basura en el piso.",
				choices: [
					"No",
					"tires",
					"la",
					"basura",
					"en",
					"el",
					"piso."
				]
			},
			{
				itemId: 60029,
				itemLabel: "CLO-W2S-29",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.892,
				correctAnswer: "La tecnología está en todas partes.",
				choices: [
					"La",
					"tecnología",
					"está",
					"en",
					"todas",
					"partes."
				]
			},
			{
				itemId: 60030,
				itemLabel: "CLO-W2S-30",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.63,
				correctAnswer: "Antes de comer, lava tus manos.",
				choices: [
					"Antes",
					"de",
					"comer,",
					"lava",
					"tus",
					"manos."
				]
			},
			{
				itemId: 60031,
				itemLabel: "CLO-W2S-31",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.791,
				correctAnswer: "La maestra quiere que pongamos atención.",
				choices: [
					"La",
					"maestra",
					"quiere",
					"que",
					"pongamos",
					"atención."
				]
			},
			{
				itemId: 60032,
				itemLabel: "CLO-W2S-32",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.287,
				correctAnswer: "Si practicamos mucho, ganaremos el partido.",
				choices: [
					"Si",
					"practicamos",
					"mucho,",
					"ganaremos",
					"el",
					"partido."
				]
			},
			{
				itemId: 60033,
				itemLabel: "CLO-W2S-33",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.497,
				correctAnswer: "Sin estudiar, es difícil sacar buena calificación.",
				choices: [
					"Sin",
					"estudiar,",
					"es",
					"difícil",
					"sacar",
					"buena",
					"calificación."
				]
			},
			{
				itemId: 60035,
				itemLabel: "CLO-W2S-35",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.412,
				correctAnswer: "Las tormentas de nieve continuarán esta semana.",
				choices: [
					"Las",
					"tormentas",
					"de",
					"nieve",
					"continuarán",
					"esta",
					"semana."
				]
			},
			{
				itemId: 60034,
				itemLabel: "CLO-W2S-34",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 0.943,
				correctAnswer: "¿Quién trajo la pelota de fútbol al parque?",
				choices: [
					"¿Quién",
					"trajo",
					"la",
					"pelota",
					"de",
					"fútbol",
					"al",
					"parque?"
				]
			},
			{
				itemId: 60036,
				itemLabel: "CLO-W2S-36",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.438,
				correctAnswer: "Los bomberos se apuraron para llegar al incendio.",
				choices: [
					"Los",
					"bomberos",
					"se",
					"apuraron",
					"para",
					"llegar",
					"al",
					"incendio."
				]
			},
			{
				itemId: 60037,
				itemLabel: "CLO-W2S-37",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.836,
				correctAnswer: "Hay aproximadamente 350 tipos de dinosaurios diferentes.",
				choices: [
					"Hay",
					"aproximadamente",
					"350",
					"tipos",
					"de",
					"dinosaurios",
					"diferentes."
				]
			},
			{
				itemId: 60038,
				itemLabel: "CLO-W2S-38",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 3.226,
				correctAnswer: "¿Qué hace que un volcán entre en erupción?",
				choices: [
					"¿Qué",
					"hace",
					"que",
					"un",
					"volcán",
					"entre",
					"en",
					"erupción?"
				]
			},
			{
				itemId: 60039,
				itemLabel: "CLO-W2S-39",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.47,
				correctAnswer: "Papá nos dijo que no corriéramos en la calle.",
				choices: [
					"Papá",
					"nos",
					"dijo",
					"que",
					"no",
					"corriéramos",
					"en",
					"la",
					"calle."
				]
			},
			{
				itemId: 60040,
				itemLabel: "CLO-W2S-40",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.353,
				correctAnswer: "Luis lava los platos mientras sus hermanas barren.",
				choices: [
					"Luis",
					"lava",
					"los",
					"platos",
					"mientras",
					"sus",
					"hermanas",
					"barren."
				]
			},
			{
				itemId: 60041,
				itemLabel: "CLO-W2S-41",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.602,
				correctAnswer: "El tesoro fue escondido por los piratas.",
				choices: [
					"El",
					"tesoro",
					"fue",
					"escondido",
					"por",
					"los",
					"piratas."
				]
			},
			{
				itemId: 60042,
				itemLabel: "CLO-W2S-42",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.264,
				correctAnswer: "Prefiero tomar leche, pero me sirvieron agua.",
				choices: [
					"Prefiero",
					"tomar",
					"leche,",
					"pero",
					"me",
					"sirvieron",
					"agua."
				]
			},
			{
				itemId: 60043,
				itemLabel: "CLO-W2S-43",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 2.815,
				correctAnswer: "Las preocupaciones por el cambio climático están creciendo.",
				choices: [
					"Las",
					"preocupaciones",
					"por",
					"el",
					"cambio",
					"climático",
					"están",
					"creciendo."
				]
			},
			{
				itemId: 60044,
				itemLabel: "CLO-W2S-44",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 2.127,
				correctAnswer: "César encontró la determinación de ayudar a sus compañeros.",
				choices: [
					"César",
					"encontró",
					"la",
					"determinación",
					"de",
					"ayudar",
					"a",
					"sus",
					"compañeros."
				]
			},
			{
				itemId: 60045,
				itemLabel: "CLO-W2S-45",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 2.427,
				correctAnswer: "Los libros electrónicos se están volviendo más comunes.",
				choices: [
					"Los",
					"libros",
					"electrónicos",
					"se",
					"están",
					"volviendo",
					"más",
					"comunes."
				]
			},
			{
				itemId: 60046,
				itemLabel: "CLO-W2S-46",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.739,
				correctAnswer: "¡A Lola le gustan los martes porque va a la biblioteca!",
				choices: [
					"¡A",
					"Lola",
					"le",
					"gustan",
					"los",
					"martes",
					"porque",
					"va",
					"a",
					"la",
					"biblioteca!"
				]
			},
			{
				itemId: 60047,
				itemLabel: "CLO-W2S-47",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 1.828,
				correctAnswer: "En el Día de los Muertos, honramos a seres queridos.",
				choices: [
					"En",
					"el",
					"Día",
					"de",
					"los",
					"Muertos,",
					"honramos",
					"a",
					"seres",
					"queridos."
				]
			},
			{
				itemId: 60048,
				itemLabel: "CLO-W2S-48",
				itemType: "words2Sentence",
				itemClass: "active",
				difficulty: 2.073,
				correctAnswer: "Es importante que los padres supervisen el uso de la tecnología.",
				choices: [
					"Es",
					"importante",
					"que",
					"los",
					"padres",
					"supervisen",
					"el",
					"uso",
					"de",
					"la",
					"tecnología."
				]
			}
		]
	},
	finale: {
		trainingItemIds: [],
		numTrainingItemRetries: 0,
		items: [
			{
				itemId: 9999,
				itemLabel: "FIN_1",
				itemType: "finale",
				itemClass: "active"
			}
		]
	},

	findItemDefByTypeAndId: function(type, id) {
		const allItems = itemsByType[type].items;
		const filteredItems = allItems.filter((item) => item.itemId === id);

		if (filteredItems.length > 0) {
			return filteredItems[0];
		} else {
			return undefined;
		}
	},

	findItemIndexByTypeAndId: function(itemType, itemId) {
		const items = this[itemType].items;
		let index;

		items.every((item, thisIndex) => {
			if (item.itemId === itemId) {
				index = thisIndex;
				return false;
			} else {
				return true;
			}
		});

		return index;
	},

	purgeItem: function(itemType, itemId) {
		// Account for merged word recognition items
		if ((itemType === "wordRecognitionDecodable") || (itemType === "wordRecognitionNonDecodable")) {
			itemType = "wordRecognition";
		}

		if (itemType && itemId) {
			// Remove the item definition
			var itemIndex = this.findItemIndexByTypeAndId(itemType, itemId);

			if (itemIndex !== -1) {
				this[itemType].items.splice(itemIndex, 1);
			}

			// If it's a training item, remove it from the training item IDs array as well
			var trainingItemIdsIndex = $.inArray(itemId, this[itemType].trainingItemIds);

			if (trainingItemIdsIndex !== -1) {
				this[itemType].trainingItemIds.splice(trainingItemIdsIndex, 1);
			}
		}
	}
};

// export default itemsByType;