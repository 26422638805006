const questionSets = {
	letterIdentification: [
		[
			1001,
			1002,
			1004,
			1006,
			1009,
			10011,
			10012,
			10014,
			10015,
			10016,
			10018,
			10019,
			10021,
			10023,
			10025
		],
		[
			1001,
			1003,
			1005,
			1007,
			1008,
			10010,
			10011,
			10013,
			10014,
			10016,
			10017,
			10018,
			10020,
			10022,
			10024
		]
	],
	soundIdentification: [
		[
			2001,
			2003,
			2004,
			2005,
			2007,
			2009,
			20011,
			20012,
			20014,
			20016,
			20018,
			20020,
			20021,
			20023,
			20024
		],
		[
			2002,
			2003,
			2005,
			2006,
			2008,
			20010,
			20012,
			20013,
			20015,
			20017,
			20019,
			20020,
			20022,
			20024
		]
	],
	syllableIdentificationEasy: [
		[
			2101,
			2102,
			2103,
			2105,
			2106,
			2108,
			21010,
			21011
		],
		[
			2101,
			2104,
			2106,
			2107,
			2109,
			21011,
			21012,
			21014
		]
	],
	syllableIdentificationMedium: [
		[
			21013,
			21015,
			21017,
			21019,
			21021,
			21023,
			21026,
			21028
		],
		[
			21016,
			21018,
			21020,
			21022,
			21024,
			21025,
			21027,
			21029
		]
	],
	syllableIdentificationHard: [
		[
			21030,
			21031,
			21033,
			21035,
			21037,
			21039,
			21041,
			21042
		],
		[
			21030,
			21032,
			21034,
			21036,
			21038,
			21040,
			21041,
			21043
		]
	],
	wordRecognitionEasy: [
		[
			4001,
			4002,
			4005,
			4007,
			4009,
			40011,
			40013,
			40015,
			40017
		],
		[
			4002,
			4003,
			4004,
			4006,
			4008,
			40010,
			40012,
			40014,
			40016
		]
	],
	wordRecognitionMedium: [
		[
			40018,
			40020,
			40022,
			40024,
			40025,
			40027,
			40029,
			40031
		],
		[
			40018,
			40019,
			40021,
			40023,
			40024,
			40026,
			40028,
			40030
		]
	],
	wordRecognitionHard: [
		[
			40033,
			40034,
			40036,
			40038,
			40040,
			40042,
			40044,
			40045,
			40047
		],
		[
			40032,
			40034,
			40035,
			40037,
			40039,
			40041,
			40043,
			40044,
			40046
		]
	],
	letters2WordEasy: [
		[
			3001,
			3002,
			3004,
			3006,
			3007,
			3009,
			30011,
			30013,
			30015
		],
		[
			3002,
			3003,
			3005,
			3006,
			3008,
			30010,
			30012,
			30014,
			30016
		]
	],
	letters2WordMedium: [
		[
			30017,
			30018,
			30020,
			30022,
			30024,
			30025,
			30027,
			30029,
			30031,
			30033,
			30035
		],
		[
			30018,
			30019,
			30021,
			30023,
			30025,
			30026,
			30028,
			30030,
			30032,
			30034,
			30036
		]
	],
	letters2WordHard: [
		[
			30037,
			30039,
			30041,
			30043,
			30045,
			30046,
			30048,
			30050,
			30052,
			30054
		],
		[
			30038,
			30040,
			30042,
			30044,
			30045,
			30047,
			30049,
			30051,
			30053,
			30055
		]
	],
	words2SentenceEasy: [
		[
			6001,
			6002,
			6004,
			6007,
			6009,
			60010,
			60012,
			60014,
			60016,
			60018
		],
		[
			6001,
			6003,
			6005,
			6006,
			6008,
			60010,
			60011,
			60013,
			60015,
			60017
		]
	],
	words2SentenceMedium: [
		[
			60020,
			60022,
			60024,
			60026,
			60028,
			60029,
			60031,
			60033,
			60035
		],
		[
			60019,
			60021,
			60023,
			60025,
			60027,
			60028,
			60030,
			60032
		]
	],
	words2SentenceHard: [
		[
			60037,
			60039,
			60040,
			60042,
			60044,
			60046,
			60047,
			60048
		],
		[
			60034,
			60036,
			60038,
			60040,
			60041,
			60043,
			60045,
			60046,
			60048
		]
	]
};
