function Letters2Meaning_LetterSelectItem(testAreaDiv, itemDef) {
	logging.logVerbose("Letters2Meaning_LetterSelectItem constructor");

	// Save incoming parameters
	this._testAreaDiv = testAreaDiv;
	this._itemDef = itemDef;

	// Layout attributes
	this._choiceFontSize = 82;
	this._textMetrics = app.getFontMetrics("ProximaNovaSoft-Bold,Arial,sans-serif",
		`${this._choiceFontSize}px`,
		"normal");
	logging.logVerbose(`this._textMetrics: ${JSON.stringify(this._textMetrics)}`);

	// No answer yet
	this.currentAnswer = undefined;
	this.answerCorrect = false;

	// Construct the item in the DOM
	this._build();
}

Letters2Meaning_LetterSelectItem.prototype._build = function() {
	logging.logVerbose("Letters2Meaning_LetterSelectItem._build()");

	if (this._testAreaDiv && this._itemDef) {
		// Construct the answer area
		var answerArea = app.buildAnswerArea("letterSelectAnswerArea", this._testAreaDiv);

		// Style the answer area based on the testAreaDiv's styling
		var parentCSS = this._testAreaDiv.css([
			"left",
			"width",
			"height"
		]);
		var parentLeft = app.getCSSPropertyAsNumber(parentCSS.left);
		var parentWidth = app.getCSSPropertyAsNumber(parentCSS.width);
		var parentHeight = app.getCSSPropertyAsNumber(parentCSS.height);

		var answerAreaHeight = this._textMetrics.heightAboveBaseline;
		var answerAreaMarginX = answerAreaHeight / 2;
		var answerAreaTop = (parentHeight - answerAreaHeight) / 2;
		var answerAreaLeft = parentLeft + answerAreaMarginX;
		var answerAreaWidth = parentWidth - (2 * answerAreaMarginX);
		answerArea.css({
			"top": `${answerAreaTop}px`,
			"left": `${answerAreaLeft}px`,
			"width": `${answerAreaWidth}px`,
			"height": `${answerAreaHeight}px`,
			"position": "absolute",
			"font-size": `${this._choiceFontSize}px`,
			"text-align": "center",
			"white-space": "nowrap"
		});

		// Evenly space out the choices based on the answer area width
		var choiceSpacing = answerAreaWidth / (this._itemDef.choices.length + 1);

		// Clone the choices for randomization
		var choices = this._itemDef.choices.slice(0);

		// For each choice in the item...
		for (var i = 1; i <= this._itemDef.choices.length; i++) {
			// Generate the ID for the choice
			var choiceId = `letterSelectChoice${i}of${this._itemDef.choices.length}`;

			// Get the randomized choice text
			var choiceTextIdx = Math.floor(Math.random() * choices.length);
			var choiceText = choices[choiceTextIdx];
			choices.splice(choiceTextIdx, 1);

			// Create a div for the choice
			var choice = $("<div/>", {
				"id": choiceId,
				"class": "clickableChoiceUnselected",
				"text": choiceText,
				"click": this._onChoiceSelect,
				"css": {
					"display": "inline-block",
					"position": "absolute",
					"top": `${0 + this._textMetrics.topOffset}px`,
					"left": `${(i * choiceSpacing) + this._textMetrics.leftOffset}px`,
					"width": `${this._textMetrics.width}px`,
					"height": `${this._textMetrics.height}px`,
					"font-size": `${this._choiceFontSize}px`,
					"text-align": "center",
					"white-space": "nowrap",
					"vertical-align": "baseline"
				}
			});

			// Create an anchor pixel at the bottom of the choice div to set the text baseline properly
			var anchorImage = $("<img/>", {
				"src": "images/1x1.gif",
				"css": {
					"position": "relative",
					"margin-top": `${this._textMetrics.anchorImgMarginTop}px`
				}
			}).appendTo(choice);

			// Append the div to the answer area
			answerArea.append(choice);
		}
	}
};

Letters2Meaning_LetterSelectItem.prototype._onChoiceSelect = function(evt) {
	logging.logVerbose("Letters2Meaning_LetterSelectItem._onChoiceSelect()");

	// Deselect any existing choice selection
	$('.clickableChoiceSelected').removeClass('clickableChoiceSelected').addClass('clickableChoiceUnselected');

	// Select the current choice
	$(evt.target).removeClass('clickableChoiceUnselected').addClass('clickableChoiceSelected');

	// Tell the audio module we've responded
	audio.setHasResponded(true);

	// Update the current answer
	app._currentItem._updateAnswer();
};

Letters2Meaning_LetterSelectItem.prototype._getAnswer = function() {
	logging.logVerbose("Letters2Meaning_LetterSelectItem._getAnswer()");

	var currentAnswer = undefined;

	// The answer is the currently selected choice
	var selected = $('.clickableChoiceSelected');
	if (selected) {
		currentAnswer = $(selected[0]).text();
	}

	logging.logVerbose(`  current answer: ${currentAnswer}, previous answer = ${this.currentAnswer}`);

	if (currentAnswer !== this.currentAnswer) {
		this.currentAnswer = currentAnswer;
		this.answerCorrect = (this.currentAnswer == this._itemDef.correctAnswer);
		logging.logVerbose(`  answerCorrect = ${this.answerCorrect}`);

		// Log the response
		logging.logItemResponse(app.curPageName,
			this._itemDef.itemId,
			this._itemDef.itemLabel,
			-1,
			this.currentAnswer,
			this.answerCorrect,
			true);
	}
};

Letters2Meaning_LetterSelectItem.prototype._updateAnswer = function() {
	logging.logVerbose("Letters2Meaning_LetterSelectItem._updateAnswer()");

	this._getAnswer();

	// Update the "Next" button -- only enable it if we have an answer
	var enabled = ((this.currentAnswer != null) &&
                   (this.currentAnswer != undefined) &&
                   (this.currentAnswer != ""));
	app.enableNextButton(enabled);
};
