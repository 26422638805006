function Letters2Meaning_WordSelectItem(testAreaDiv, itemDef) {
	logging.logVerbose("Letters2Meaning_WordSelectItem constructor");

	// Save incoming parameters
	this._testAreaDiv = testAreaDiv;
	this._itemDef = itemDef;

	/*
    // No answer area yet
    this._answerArea = undefined;
    this._answerAreaTop = undefined;
    this._answerAreaLeft = undefined;
    this._answerAreaWidth = undefined;
    this._answerAreaHeight = undefined;
*/

	// Layout attributes
	this._choiceFontSize = undefined;
	this._textMetrics = undefined;
	this._maxChoiceWidth = undefined;
	this._choiceColumnMarginX = 20;

	// Insertion cursor
	this._insertCursor = undefined;
	this._cursorWidth = undefined;

	// Lookup table for choices, indexed by IDs, holding their original layout coordinates
	// (for restoring choices to their original positions when removed from answer) and
	// cacheing their pixel widths.
	this._choiceData = {};

	// No answer yet
	this.currentAnswer = undefined;
	this.answerCorrect = false;
	this._answerChoices = [];

	// Construct the item in the DOM
	this._build();
}

Letters2Meaning_WordSelectItem.prototype._build = function() {
	logging.logVerbose("Letters2Meaning_WordSelectItem._build()");

	if (this._testAreaDiv && this._itemDef) {
		// Get a reference to ourselves so we can access ourselves in handler contexts
		var that = this;

		/*
        // Construct a wrapper matching the parent test area (container for drag/drop)
        this._testAreaDroppableWrapper = $("<div/>", {
            "class": "testAreaDroppableWrapper"
        }).appendTo(this._testAreaDiv);

        // Make it droppable for answer choices & set handler
        this._testAreaDroppableWrapper.droppable({
            accept: ".wordSelectChoice",
            tolerance: "intersect",
            drop: function(evt, uiObj) {
                //logging.logVerbose("Dropped in test area");

                // Remove the object from our answer array
                that._removeChoiceFromAnswer(uiObj.draggable);

                // Put the choice back in its original position
                that._returnChoiceToOrigin(uiObj.draggable);
            }
        });

        // Construct frames for the choice & answer areas
        this._choiceAreaFrame = $("<div/>", {
            "class": "choiceAreaFrame"
        }).appendTo(this._testAreaDroppableWrapper);
        this._answerAreaFrame = $("<div/>", {
            "class": "answerAreaFrame"
        }).appendTo(this._testAreaDroppableWrapper);
*/

		// Get the parent's styling (for configuring answer area)
		//var parentCSS = this._testAreaDroppableWrapper.css([
		var parentCSS = this._testAreaDiv.css([
			"left",
			"width",
			"height"
		]);
		var parentLeft = app.getCSSPropertyAsNumber(parentCSS.left);
		var parentWidth = app.getCSSPropertyAsNumber(parentCSS.width);
		var parentHeight = app.getCSSPropertyAsNumber(parentCSS.height);

		// Construct a wrapper matching the parent test area
		this._choiceAreaFrame = $("<div/>", {
			"class": "choiceAreaFrame",
			"css": {
				"top": `${0}px`,
				"left": `${0}px`,
				"width": `${parentWidth}px`,
				"height": `${parentHeight}px`,
				"position": "absolute"
			}
		}).appendTo(this._testAreaDiv);

		// Get the metrics needed to fit any of the choices in one column (of three) in the choice area
		var itemMetrics = this._getItemMetrics({
			"frameWidth": (parentWidth / 3) - (2 * this._choiceColumnMarginX),
			"frameHeight": parentHeight,
			"fontFamilyCSS": "ProximaNovaSoft-Bold,Arial,sans-serif",
			"initialFontSize": 82,
			"fontWeightCSS": "normal",
			"lineSpacingRatio": 1.4,
			"minMarginXRight": 0
		});
		this._choiceFontSize = itemMetrics.fontSize;
		this._maxChoiceWidth = itemMetrics.blockWidth;

		// Now that we have a workable font size, get the text metrics
		this._textMetrics = app.getFontMetrics("ProximaNovaSoft-Bold,Arial,sans-serif",
			`${this._choiceFontSize}px`,
			"normal");
		logging.logVerbose(`this._textMetrics: ${JSON.stringify(this._textMetrics)}`);

		/*
        // Construct the answer area
        this._answerArea = app.buildAnswerArea("wordSelectAnswerArea", this._testAreaDroppableWrapper);

        var answerAreaMarginX = 20;
        this._answerAreaHeight = this._textMetrics.heightAboveBaseline;
        this._answerAreaTop = (parentHeight - this._answerAreaHeight) / 2;
        this._answerAreaLeft = parentLeft + (parentWidth / 2) + answerAreaMarginX;
        this._answerAreaWidth = (parentWidth / 2) - (2 * answerAreaMarginX);
        this._answerArea.css({
            "top": this._answerAreaTop + "px",
            "left": this._answerAreaLeft + "px",
            "width": this._answerAreaWidth + "px",
            "height": this._answerAreaHeight + "px",
            "position": "absolute",
            "font-size": this._choiceFontSize + "px",
            "text-align": "center",
            "white-space": "nowrap"
        });

        // Make the answer area droppable for choices & set handler
        this._answerArea.droppable({
            accept: ".wordSelectChoice",
            greedy: true,
            tolerance: "intersect",
            drop: function(evt, uiObj) {
                //logging.logVerbose("Dropped in answer area");

                // Hide the insertion cursor
                that._insertCursor.addClass("hidden");

                // Insert the object into our answer array based on current center-X coordinate
                that._addChoiceToAnswer(uiObj.draggable);
            },
            over: function(evt, uiObj) {
                //logging.logVerbose("Choice entering answer area");

                // Make the insertion cursor visible
                that._insertCursor.removeClass("hidden");
            },
            out: function(evt, uiObj) {
                //logging.logVerbose("Choice leaving answer area");

                // Hide the insertion cursor
                that._insertCursor.addClass("hidden");
            }
        });

        // Add the insertion cursor to the answer area, with
        // top & height based on the answer area
        this._cursorWidth = Math.max(10, this._answerAreaHeight / 4);
        this._insertCursor = $("<div/>", {
            "class": "insertCursor hidden",
            "css": {
                "top": "0px",
                "width": this._cursorWidth + "px",
                "height": this._answerAreaHeight + "px"
            }
        });
        this._insertCursor.appendTo(this._answerArea);
*/

		/*
        // DEBUG: Draw vertical red line at center of answer area
        $("<div/>", {
            css: {
                "top": "0px",
                "left": (3 * (parentWidth / 8)) + "px",
                "width": "0px",
                "height": parentHeight + "px",
                "border": "1px solid red",
                "position": "absolute"
            }
        }).appendTo(this._choiceAreaFrame);

        $("<div/>", {
            css: {
                "top": "0px",
                "left": (parentWidth / 8) + "px",
                "width": "0px",
                "height": parentHeight + "px",
                "border": "1px solid red",
                "position": "absolute"
            }
        }).appendTo(this._choiceAreaFrame);
*/

		// Get the layout for the choices specifed in the item definition
		var choiceLayout = this._getChoiceLayout();

		// For each choice in the item...
		for (var i = 0; i < this._itemDef.choices.length; i++) {
			// Generate the ID for the choice
			var choiceId = `wordSelectChoice${i + 1}of${this._itemDef.choices.length}`;

			// Get the choice's text metrics (for the pixel width)
			var choiceMetrics = app.measureText("ProximaNovaSoft-Bold,Arial,sans-serif",
				`${this._choiceFontSize}px`,
				"normal",
				this._itemDef.choices[i]);

			// Compute the choice's start position
			var originalTop = `${choiceLayout[i].y + this._textMetrics.topOffset}px`;
			var originalLeft = `${choiceLayout[i].x + choiceMetrics.leftOffset}px`;

			// Record start position & text metrics
			this._choiceData[choiceId] = {
				"originalTop": originalTop,
				"originalLeft": originalLeft,
				"metrics": choiceMetrics
			};

			// Create a clickable div for the choice
			var choice = $("<div/>", {
				"id": choiceId,
				"class": "wordSelectChoice clickableChoiceUnselected",
				"text": this._itemDef.choices[i],
				"click": this._onChoiceSelect,
				"css": {
					"display": "inline-block",
					"position": "absolute",
					"top": originalTop,
					"left": originalLeft,
					"width": `${choiceMetrics.measureWidth}px`,
					"height": `${this._textMetrics.height}px`,
					"font-size": `${this._choiceFontSize}px`,
					"text-align": "center",
					"white-space": "nowrap",
					"vertical-align": "baseline"
				}
			});
			/*
            }).draggable({
                containment: this._testAreaDroppableWrapper,
                revert: "invalid",
                start: function(evt, uiObj) {
                    //logging.logVerbose("Drag Start");
                    uiObj.helper.removeClass("draggableChoiceUnselected").addClass("draggableChoiceSelected");

                    // Tell the audio module we've responded
                    audio.setHasResponded(true);
                },
                drag: function(evt, uiObj) {
                    var cursorLeft = that._getCursorLeft(uiObj.position.top, uiObj.position.left);
                    that._insertCursor.css({
                        "left": cursorLeft + "px"
                    });
                },
                stop: function(evt, uiObj) {
                    //logging.logVerbose("Drag Stop");

                    // If the choice is no longer part of the answer...
                    if (!uiObj.helper.hasClass("partOfAnswer")) {
                        // Deselect the choice
                        uiObj.helper.removeClass("draggableChoiceSelected").addClass("draggableChoiceUnselected");
                    }

                    // Update the answer
                    that._updateAnswer();
                }
            });
*/

			// Create an anchor pixel at the bottom of the choice div to set the text baseline properly
			var anchorImage = $("<img/>", {
				"src": "images/1x1.gif",
				"css": {
					"position": "relative",
					"margin-top": `${this._textMetrics.anchorImgMarginTop}px`
				}
			}).appendTo(choice);

			// Append it to the selection area (test area)
			//this._testAreaDroppableWrapper.append(choice);
			// Append it to the choice area
			this._choiceAreaFrame.append(choice);
		}
	}
};

Letters2Meaning_WordSelectItem.prototype._getItemMetrics = function(params) {
	logging.logVerbose(`Letters2Meaning_WordSelectItem._getItemMetrics(${JSON.stringify(params)})`);

	var itemMetrics = undefined;

	if (params) {
		// Extract parameters
		var frameWidth          = params.frameWidth;
		var frameHeight         = params.frameHeight;
		var fontFamilyCSS       = params.fontFamilyCSS;
		var initialFontSize     = params.initialFontSize;
		var fontWeightCSS       = params.fontWeightCSS;
		var lineSpacingRatio    = params.lineSpacingRatio;
		var minMarginXRight     = params.minMarginXRight;

		if (frameWidth && frameHeight) {
			// Get the width of the widest choice defined at the initial font size
			var widestChoice = app.getWidestChoice(this._itemDef, fontFamilyCSS, `${initialFontSize}px`, fontWeightCSS);
			var widestChoiceIdx = widestChoice.widestChoiceIdx;

			// Get the font size that allows the choices to fit in the given frame, based on the maximum choice width
			itemMetrics = this._adaptChoicesToFrame({
				"textToFit": this._itemDef.choices[widestChoiceIdx],
				"maxChoiceWidth": widestChoice.maxChoiceWidth,
				"numChoices": this._itemDef.choices.length,
				"frameWidth": frameWidth,
				"frameHeight": frameHeight,
				"fontFamilyCSS": fontFamilyCSS,
				"initialFontSize": initialFontSize,
				"fontWeightCSS": fontWeightCSS,
				"lineSpacingRatio": lineSpacingRatio,
				"minMarginXRight": minMarginXRight
			});
		}
	}

	return itemMetrics;
};

Letters2Meaning_WordSelectItem.prototype._adaptChoicesToFrame = function(params) {
	logging.logVerbose(`Letters2Meaning_WordSelectItem._adaptChoicesToFrame(${JSON.stringify(params)})`);

	var adaptedMetrics = undefined;

	if (params) {
		// Extract parameters
		var textToFit           = params.textToFit;
		var maxChoiceWidth      = params.maxChoiceWidth;
		var numChoices          = params.numChoices;
		var frameWidth          = params.frameWidth;
		var frameHeight         = params.frameHeight;
		var fontFamilyCSS       = params.fontFamilyCSS;
		var initialFontSize     = params.initialFontSize;
		var fontWeightCSS       = params.fontWeightCSS;
		var lineSpacingRatio    = params.lineSpacingRatio;
		var minMarginXRight     = 0;    // params.minMarginXRight;  // unused

		if (textToFit && maxChoiceWidth && numChoices && frameWidth && frameHeight) {
			var marginXLeft = 0;
			var marginXRight = 0;
			var choiceMetrics;
			var fontSize = initialFontSize;

			// Three choices per line
			var blockWidth = maxChoiceWidth;
			var numLines = Math.ceil(numChoices / 3);
			logging.logVerbose(`  numLines = ${numLines}`);

			if ((blockWidth + minMarginXRight) > frameWidth) {
				// Iterate the font size until the metrics meet the width constraint from the frame
				while ((blockWidth + minMarginXRight) > frameWidth) {
					// Decrease the font size accordingly
					fontSize = Math.max(9, Math.floor(fontSize * (frameWidth / (blockWidth + minMarginXRight))));
					logging.logVerbose(`  Shrinking font size to ${fontSize}`);

					// Re-measure the widest choice at the new font size
					choiceMetrics = app.measureText(fontFamilyCSS, `${fontSize}px`, fontWeightCSS, textToFit);
					logging.logVerbose(`  choiceMetrics: ${JSON.stringify(choiceMetrics)}`);

					// Update the block width & margin
					blockWidth = choiceMetrics.measureWidth;
					logging.logVerbose(`  new blockWidth = ${blockWidth}`);
				}
			}

			// Width constraint met -- how about the height?
			var blockHeight = (numLines * fontSize) + ((numLines - 1) * (fontSize * (lineSpacingRatio - 1)));
			logging.logVerbose(`  blockHeight = ${blockHeight}`);
			while ((blockHeight > frameHeight) && (fontSize >= 9)) {
				// Block of text is too tall -- iterate further to reduce font size & cram it all in
				fontSize--;
				choiceMetrics = app.measureText(fontFamilyCSS, `${fontSize}px`, fontWeightCSS, textToFit);

				// Update block sizes
				blockHeight = (numLines * fontSize) + ((numLines - 1) * (fontSize * (lineSpacingRatio - 1)));
				blockWidth = choiceMetrics.measureWidth;
			}
			logging.logVerbose(`  new blockHeight = ${blockHeight}`);
			logging.logVerbose(`  new blockWidth = ${blockWidth}`);

			if ((blockWidth + (2 * minMarginXRight)) <= frameWidth) {
				// Plenty of room for symmetric margins -- split the whitespace evenly
				marginXRight = (frameWidth - blockWidth) / 2;
				marginXLeft = marginXRight;
			} else {
				// Room for right-hand margin, but not left
				marginXRight = minMarginXRight;
				marginXLeft = ((frameWidth - blockWidth) / 2) - marginXRight;
			}

			adaptedMetrics = {
				"marginXLeft": marginXLeft,
				"marginYTop": (frameHeight - blockHeight) / 2,
				"fontSize": fontSize,
				"blockWidth": blockWidth,
				"blockHeight": blockHeight,
				"lineSpacing": (fontSize * (lineSpacingRatio - 1))
			};
		}
	}

	return adaptedMetrics;
};

Letters2Meaning_WordSelectItem.prototype._getChoiceLayout = function() {
	logging.logVerbose("Letters2Meaning_WordSelectItem._getChoiceLayout()");

	var layout = [];

	// Get the layout of the choice area
	var parentCSS = this._choiceAreaFrame.css([
		"left",
		"width",
		"height"
	]);
	var parentLeft = app.getCSSPropertyAsNumber(parentCSS.left);
	var parentWidth = app.getCSSPropertyAsNumber(parentCSS.width);
	var parentHeight = app.getCSSPropertyAsNumber(parentCSS.height);

	// Define a grid on the choice area based on the current font metrics
	var numCols = 3;    //Math.floor(parentWidth / this._maxChoiceWidth);
	var numRows = Math.floor(parentHeight / this._textMetrics.height);
	var marginTop = (parentHeight % this._textMetrics.height) / 2;
	var marginLeft = parentWidth / 6;

	logging.logVerbose(`parentWidth = ${parentWidth}, numCols = ${numCols}, numRows = ${numRows}, marginTop = ${marginTop}, marginLeft = ${marginLeft}`);

	// Set up an array of row/column pairs so we can randomly select them
	var cellArray = [];
	for (var i = 0; i < numRows; i++) {
		for (var j = 0; j < numCols; j++) {
			cellArray.push({"col": j, "row": i});
		}
	}

	var cellIdx;
	var cell;
	if (this._itemDef.itemClass == "training") {
		// Generate enough unique cell indices to hold our choices
		var cellIdxAry = [];
		while (cellIdxAry.length < this._itemDef.choices.length) {
			cellIdx = Math.floor(Math.random() * cellArray.length);
			if ($.inArray(cellIdx, cellIdxAry) == -1) {
				cellIdxAry.push(cellIdx);
			}
		}

		// Fill the layout with our ordered (but randomly-selected) cell coordinates
		for (var i = 0; (i < cellArray.length) && (layout.length < this._itemDef.choices.length); i++) {
			if ($.inArray(i, cellIdxAry) != -1) {
				// Generate a layout coordinate for the cell
				layout.push({
					"x": Math.floor(marginLeft + (cellArray[i].col * (parentWidth / 3))),
					"y": Math.floor(marginTop + (cellArray[i].row * this._textMetrics.height))
				});
			}
		}
	} else {
		// Select random cells to hold the choices
		for (var i = 0; i < this._itemDef.choices.length; i++) {
			// Select a random cell index
			cellIdx = Math.floor(Math.random() * cellArray.length);

			// Remove the randomly-selected cell from the array,
			// so we don't choose it again
			cell = cellArray.splice(cellIdx, 1)[0];

			// Generate a layout coordinate for the cell
			layout.push({
				"x": Math.floor(marginLeft + (cell.col * (parentWidth / 3))),
				"y": Math.floor(marginTop + (cell.row * this._textMetrics.height))
			});
		}
	}

	logging.logVerbose(`  layout: ${JSON.stringify(layout)}`);
	return layout;
};

/*
Letters2Meaning_WordSelectItem.prototype._addChoiceToAnswer = function(choice)
{
    logging.logVerbose("Letters2Meaning_WordSelectItem._addChoiceToAnswer()");

    // If the choice is already part of the answer, ...
    if (choice.hasClass("partOfAnswer")) {
        // ...return without doing anything
        return;
    }

    // Any existing answer choices are no longer answer choices
    for (var i = 0; i < this._answerChoices.length; i++) {
        var oldChoice = this._answerChoices[i].choice;
        this._removeChoiceFromAnswer(oldChoice);
        this._returnChoiceToOrigin(oldChoice);
    }

    // Signal that the current choice should now be considered part of the answer
    choice.addClass("partOfAnswer");

    // Insert the object into our answer array
    this._answerChoices.splice(i, 0, {"choice": choice});
};

Letters2Meaning_WordSelectItem.prototype._removeChoiceFromAnswer = function(choice)
{
    logging.logVerbose("Letters2Meaning_WordSelectItem._removeChoiceFromAnswer()");

    // Signal that the choice should no longer be considered part of the answer
    choice.removeClass("partOfAnswer");

    // Remove the object from our answer array
    var choiceId = choice.attr("id");
    logging.logVerbose("  choice ID: " + choiceId);
    for (var i = 0; i < this._answerChoices.length; i++) {
        if (this._answerChoices[i].choice.attr("id") == choiceId) {
            logging.logVerbose("  index in answerChoices: " + i);
            break;
        }
    }
    this._answerChoices.splice(i, 1);
};

Letters2Meaning_WordSelectItem.prototype._returnChoiceToOrigin = function(choice)
{
    logging.logVerbose("Letters2Meaning_WordSelectItem._returnChoiceToOrigin()");

    // Make sure choice is deselected
    choice.removeClass("draggableChoiceSelected").addClass("draggableChoiceUnselected");

    // Put the choice back in its original position
    var choiceData = this._choiceData[choice.attr("id")];
    if (choiceData) {
        logging.logVerbose("originalTop: " + choiceData.originalTop + ", originalLeft: " + choiceData.originalLeft);
        choice.animate({
            "top": choiceData.originalTop,
            "left": choiceData.originalLeft
        }, 500);
    }
};

Letters2Meaning_WordSelectItem.prototype._getCursorLeft = function(top, left)
{
    var cursorLeft = (this._answerAreaWidth / 2) - (this._cursorWidth / 2);

    return cursorLeft;
};

Letters2Meaning_WordSelectItem.prototype._arrangeAnswerChoices = function()
{
    if (this._answerChoices.length > 0)
    {
        var currentChoiceCenterX = this._answerAreaLeft +             // Left end of the answer area
                                    (this._answerAreaWidth / 2);      // Center of answer area
        var choiceMetrics = this._choiceData[this._answerChoices[0].choice.attr("id")].metrics;

        // Reposition the choice in the answer area
        this._answerChoices[0].choice.css({
            "top": this._answerAreaTop + this._textMetrics.topOffset,
            "left": currentChoiceCenterX + choiceMetrics.leftOffset
        });
    }
};
*/

Letters2Meaning_WordSelectItem.prototype._onChoiceSelect = function(evt) {
	logging.logVerbose("Letters2Meaning_WordSelectItem._onChoiceSelect()");

	// Deselect any existing choice selection
	$('.clickableChoiceSelected').removeClass('clickableChoiceSelected').addClass('clickableChoiceUnselected');

	// Select the current choice
	$(evt.target).removeClass('clickableChoiceUnselected').addClass('clickableChoiceSelected');

	// Tell the audio module we've responded
	audio.setHasResponded(true);

	// Update the current answer
	app._currentItem._updateAnswer();
};

Letters2Meaning_WordSelectItem.prototype._getAnswer = function() {
/*    this.currentAnswer = "";
    for (var i = 0; i < this._answerChoices.length; i++) {
        this.currentAnswer += this._answerChoices[i].choice.text();
    }
    logging.logVerbose("  current answer: " + this.currentAnswer);

    this.answerCorrect = (this.currentAnswer == this._itemDef.correctAnswer);
    logging.logVerbose("  answerCorrect = " + this.answerCorrect);
*/

	var currentAnswer = undefined;

	// The answer is the currently selected choice
	var selected = $('.clickableChoiceSelected');
	if (selected) {
		currentAnswer = $(selected[0]).text();
	}

	logging.logVerbose(`  current answer: ${currentAnswer}, previous answer = ${this.currentAnswer}`);


	if (currentAnswer !== this.currentAnswer) {
		this.currentAnswer = currentAnswer;
		this.answerCorrect = (this.currentAnswer == this._itemDef.correctAnswer);
		logging.logVerbose(`  answerCorrect = ${this.answerCorrect}`);

		// Log the response
		logging.logItemResponse(app.curPageName,
			this._itemDef.itemId,
			this._itemDef.itemLabel,
			-1,
			this.currentAnswer,
			this.answerCorrect,
			true);
	}
};

Letters2Meaning_WordSelectItem.prototype._updateAnswer = function() {
	logging.logVerbose("Letters2Meaning_WordSelectItem._updateAnswer()");

	// Update the positions of the answer choices to tidy everything up
	//this._arrangeAnswerChoices();

	this._getAnswer();

	// Update the "Next" button -- only enable it if we have an answer
	var enabled = ((this.currentAnswer != null) &&
                   (this.currentAnswer != undefined) &&
                   (this.currentAnswer != ""));
	app.enableNextButton(enabled);
};
