function Letters2Meaning_Words2SentenceItem(testAreaDiv, itemDef) {
	logging.logVerbose("Letters2Meaning_Words2SentenceItem constructor");

	// Save incoming parameters
	this._testAreaDiv = testAreaDiv;
	this._itemDef = itemDef;

	// No answer area yet
	this._answerAreas = [];
	this._currentAnswerArea = undefined;
	this._answerAreaMarginX = 20;
	this._answersInAnswerAreaMarginX = 20;

	// Layout attributes
	this._choiceFontSize = undefined;
	this._answerAreaTextMetrics = undefined;
	this._choiceColumnMarginX = 20;

	// Insertion cursor
	this._currentInsertCursor = undefined;
	this._currentCursorLeft = undefined;
	this._cursorWidth = undefined;

	// Lookup table for choices, indexed by IDs, holding their original layout coordinates
	// (for restoring choices to their original positions when removed from answer) and
	// cacheing their pixel widths.
	this._choiceData = {};

	// No answer yet
	this.currentAnswer = undefined;
	this.answerCorrect = false;
	this._answerChoices = [];

	// Character considered punctuation (in need of special handling
	this._punctuation = [ ".", ",", "?", "!", ";", ":" ];

	// Construct the item in the DOM
	this._build();
}

Letters2Meaning_Words2SentenceItem.prototype._build = function() {
	logging.logVerbose("Letters2Meaning_Words2SentenceItem._build()");

	if (this._testAreaDiv && this._itemDef) {
		// Get a reference to ourselves so we can access ourselves in handler contexts
		var that = this;
		var i;

		// Construct a wrapper matching the parent test area (container for drag/drop)
		this._testAreaDroppableWrapper = $("<div/>", {
			"class": "testAreaDroppableWrapper"
		}).appendTo(this._testAreaDiv);

		// Make it droppable for answer choices & set the handler
		this._testAreaDroppableWrapper.droppable({
			accept: ".words2SentenceChoice",
			tolerance: "intersect",
			drop: function(evt, uiObj) {
				//logging.logVerbose("Dropped in test area");

				if (!$(evt.target).hasClass('clicked')) {
					// Remove the object from our answer array
					that._removeChoiceFromAnswer(uiObj.draggable);

					// Put the choice back in its original position
					that._returnChoiceToOrigin(uiObj.draggable);
				}
			}
		});

		// Construct frames for the choice & answer areas
		this._choiceAreaFrame = $("<div/>", {
			"class": "choiceAreaFrame"
		}).appendTo(this._testAreaDroppableWrapper);
		this._answerAreaFrame = $("<div/>", {
			"class": "answerAreaFrame"
		}).appendTo(this._testAreaDroppableWrapper);

		// Get the parent's styling (for configuring answer areas)
		var parentCSS = this._testAreaDroppableWrapper.css([
			"left",
			"width",
			"height"
		]);
		var parentLeft = app.getCSSPropertyAsNumber(parentCSS.left);
		var parentWidth = app.getCSSPropertyAsNumber(parentCSS.width);
		var parentHeight = app.getCSSPropertyAsNumber(parentCSS.height);

		// Get the metrics needed to fit any of the choices in the choice area
		var itemMetrics = this._getItemMetrics({
			"frameWidth": (parentWidth / 2),
			"frameHeight": parentHeight,
			"fontFamilyCSS": "ProximaNovaSoft-Bold,Arial,sans-serif",
			"initialFontSize": 82,
			"fontWeightCSS": "normal",
			"lineSpacingRatio": 1.4,
			"minMarginX": this._choiceColumnMarginX
		});
		logging.logVerbose(`itemMetrics = ${JSON.stringify(itemMetrics)}`);
		this._choiceFontSize = itemMetrics.fontSize;
		this._maxChoiceWidth = itemMetrics.blockWidth;

		// Now that we have a workable font size, get the text metrics
		this._textMetrics = app.getFontMetrics("ProximaNovaSoft-Bold,Arial,sans-serif",
			`${this._choiceFontSize}px`,
			"normal");
		logging.logVerbose(`this._textMetrics: ${JSON.stringify(this._textMetrics)}`);

		// Compute invariants (height, marginX, left, width) for all answer areas
		var answerAreaHeight = this._textMetrics.heightAboveBaseline;
		var answerAreaLeft = (parentWidth / 2) + this._answerAreaMarginX;
		var answerAreaWidth = (parentWidth / 2) - (2 * this._answerAreaMarginX);
		var answerAreaLineSpacing = itemMetrics.lineSpacing;
		this._cursorWidth = Math.max(10, answerAreaHeight / 4);

		// Get the width of all of the choices to determine the number of answer areas we need
		var allChoicesWidth = this._getAllChoicesOverallWidth("ProximaNovaSoft-Bold,Arial,sans-serif",
			`${this._choiceFontSize}px`,
			"normal");
		var numAnswerAreas = Math.ceil(allChoicesWidth / (answerAreaWidth - (2 * this._answersInAnswerAreaMarginX))) + 1;
		var blockHeight = (numAnswerAreas * answerAreaHeight) + ((numAnswerAreas - 1) * answerAreaLineSpacing);

		// Get the top of the topmost answer area
		var answerAreaTop = (parentHeight - blockHeight) / 2;

		// Construct the answer areas
		for (var i = 0; i < numAnswerAreas; i++) {
			// Instantiate the DOM object, style it, and attach it to the answer area
			var answerArea = app.buildAnswerArea(`words2SentenceAnswerArea${i + 1}`, this._testAreaDroppableWrapper);
			answerArea.css({
				"top": `${answerAreaTop}px`,
				"left": `${answerAreaLeft}px`,
				"width": `${answerAreaWidth}px`,
				"height": `${answerAreaHeight}px`,
				"position": "absolute",
				"font-size": `${this._choiceFontSize}px`,
				"text-align": "center",
				"white-space": "nowrap"
			});

			// Make the answer area droppable for choices & set handler
			answerArea.droppable({
				accept: ".words2SentenceChoice",
				greedy: true,
				tolerance: "intersect",
				drop: function(evt, uiObj) {
					//logging.logVerbose("Dropped in answer area");

					// Hide the insertion cursor
					$(this).find("div.insertCursor").addClass("hidden");

					// Insert the object into our answer array based on current center-X coordinate
					that._addChoiceToAnswer($(this), uiObj.draggable);
				},
				over: function(evt, uiObj) {
					//logging.logVerbose("Choice entering answer area");

					// Keep track of the current answer area (for cursor positioning)
					that._currentAnswerArea = $(this);

					// Get the answer area's insertion cursor
					var insertCursor = $(this).find("div.insertCursor")[0];
					if (insertCursor) {
						// Make it the global current cursor
						that._currentInsertCursor = $(insertCursor);

						// Set the insertion cursor position
						that._currentInsertCursor.css({
							"left": `${that._currentCursorLeft}px`
						});

						// Make the insertion cursor visible
						that._currentInsertCursor.removeClass("hidden");
					}
				},
				out: function(evt, uiObj) {
					//logging.logVerbose("Choice leaving answer area");

					// Hide the insertion cursor
					$(this).find("div.insertCursor").addClass("hidden");
				}
			});

			// Add the insertion cursor to the answer area, with
			// top & height based on the answer area
			var insertCursor = $("<div/>", {
				"class": "insertCursor hidden",
				"css": {
					"top": "0px",
					"width": `${this._cursorWidth}px`,
					"height": `${answerAreaHeight}px`
				}
			});
			insertCursor.appendTo(answerArea);

			// Save the answer area & its layout data for later reuse
			this._answerAreas.push({
				"answerArea": answerArea,
				"top": answerAreaTop,
				"left": answerAreaLeft,
				"width": answerAreaWidth,
				"cursor": insertCursor
			});
			/*
			// DEBUG: Draw vertical red line at center of answer area
			$("<div/>", {
				css: {
					"top": "0px",
					"left": (answerAreaWidth / 2) + "px",
					"width": "0px",
					"height": answerAreaHeight + "px",
					"border": "1px solid red",
					"position": "absolute"
				}
			}).appendTo(this._answerArea);
*/

			// Update layout for next answer area
			answerAreaTop += (answerAreaHeight + answerAreaLineSpacing);
		}

		// Initialize the "current" answer area
		this._currentAnswerArea = this._answerAreas[0].answerArea;

		/*
		// DEBUG: Draw vertical red line at center of choice area
		var choiceCenter = (parentWidth / 4);
		logging.logVerbose("choiceCenter = " + choiceCenter);
		$("<div/>", {
			css: {
				"top": "0px",
				"left": choiceCenter + "px",
				"width": "0px",
				"height": parentHeight + "px",
				"border": "1px solid red",
				"position": "absolute"
			}
		}).appendTo(this._choiceAreaFrame);
*/

		// Get the layout for the choices specifed in the item definition
		var choiceLayout = this._getChoiceLayout(itemMetrics.columnCount, itemMetrics.marginXLeft);

		// For each choice in the item...
		for (var i = 0; i < this._itemDef.choices.length; i++) {
			// Generate the ID for the choice
			var choiceId = `words2SentenceChoice${i + 1}of${this._itemDef.choices.length}`;

			// Get the choice's text metrics (for the pixel width)
			var choiceMetrics = app.measureText("ProximaNovaSoft-Bold,Arial,sans-serif",
				`${this._choiceFontSize}px`,
				"normal",
				this._itemDef.choices[i]);

			// If the choice is punctuation, make sure it has a minimum width
			if ($.inArray(this._itemDef.choices[i], this._punctuation) != -1) {
				choiceMetrics.measureWidth = Math.max(choiceMetrics.measureWidth, this._textMetrics.width);
			}

			// Compute the choice's start position
			var originalTop = `${choiceLayout[i].y + this._textMetrics.topOffset}px`;
			var originalLeft = `${choiceLayout[i].x + choiceMetrics.leftOffset}px`;
			//logging.logVerbose("originalTop = " + originalTop + ", originalLeft = " + originalLeft);

			// Record start position & text metrics
			this._choiceData[choiceId] = {
				"originalTop": originalTop,
				"originalLeft": originalLeft,
				"metrics": choiceMetrics
			};

			// Create a draggable div for the choice
			var click = { x: 0, y: 0 },
				totalDragEventCount = 0;

			var choice = $("<div/>", {
				"id": choiceId,
				"class": "words2SentenceChoice draggableChoiceUnselected",
				"text": this._itemDef.choices[i],
				"mousedown": function(evt) {
					$(evt.target).removeClass("draggableChoiceUnselected").addClass("draggableChoiceDragging");
					window.clickStart = Date.now();
					totalDragEventCount = 0;
				},
				"mouseup": function(evt) {
					var $choice = $(evt.target);
					// I used to differentiate a drag from a short click by looking at the total time the mouse
					// button was held down, but that's not really the standard for UX.  A user could reasonably
					// expect to hold the mouse down in a click for a second or two, then release without dragging,
					// and have it act like a click.  I've switched to counting total drag events instead.
					// Note, though, that when the user clicks on a choice, the system responds by simulating drag
					// events to put the choice into the answer area.  Each simulated drag event also fires a
					// mousedown and mouseup.  Without something in place to prevent it, this will cause a loop where
					// each simulated drag event counts as a click that fires more simulated drag events.  To prevent
					// that, I'm checking for a minimum of 10ms since the mousedown event.
					if (Date.now() - window.clickStart > 10) {
						if (totalDragEventCount > 10) {
							// User is dragging
							logging.logVerbose(`User dragged choice '${$choice.text()}'`);

							if (!$choice.hasClass("partOfAnswer")) {
								$choice.removeClass("draggableChoiceDragging").addClass("draggableChoiceUnselected");
							}
						} else {
							// User clicked
							logging.logVerbose(`User clicked on choice '${$choice.text()}'`);

							var droppable,
								draggable = $choice.draggable(),
								draggableOffset = draggable.offset(),
								draggableCenterX = draggableOffset.left + (draggable.width() / 2),
								draggableCenterY = draggableOffset.top + (draggable.height() / 2),
								dx,
								dy;

							if ($choice.hasClass('partOfAnswer')) {
								droppable = that._testAreaDroppableWrapper.droppable();
							} else {
								var droppable = $('.answerAreaMidline:visible').last();
							}

							var droppableOffset = droppable.offset(),
								droppableCenterX = droppableOffset.left + (droppable.width() / 2),
								droppableCenterY = droppableOffset.top + (droppable.height() / 2);

							if ($choice.hasClass('partOfAnswer')) {
								// If this choice is already part of the answer, remove it by simulating a drag
								// back to the answer pool
								dx = droppableCenterX - draggableCenterX;
								dy = droppableCenterY - draggableCenterY;
							} else {
								// Otherwise, add it to the answer by simulating a drag into the answer area
								// Need to block the drop event in the choice's current location, because it's in the
								// answer pool, and dropping there sends it back to its original position
								$choice.addClass('clicked');
								dx = droppableCenterX - draggableCenterX;
								dy = droppableOffset.top - draggableCenterY;
								// The `simulate` method drags/drops *from the current cursor position*.  That means that
								// if the cursor is too far from center, the item being dragged might not hit the
								// intended target.
								var mouseOffsetY = evt.pageY - draggableCenterY;
								dy += mouseOffsetY;
							}
							draggable.simulate("drag", {
								dx: dx,
								dy: dy
							});
						}
					}
				},
				"css": {
					"display": "inline-block",
					"position": "absolute",
					"top": originalTop,
					"left": originalLeft,
					"width": `${choiceMetrics.measureWidth}px`,
					"height": `${this._textMetrics.height}px`,
					"font-size": `${this._choiceFontSize}px`,
					"text-align": "center",
					"white-space": "nowrap",
					"vertical-align": "baseline"
				}
			}).draggable({
				containment: this._testAreaDroppableWrapper,
				revert: "invalid",
				start: function(evt, uiObj) {
					//logging.logVerbose("Drag Start");
					//uiObj.helper.removeClass("draggableChoiceUnselected").addClass("draggableChoiceSelected");

					// Tell the audio module we've responded
					audio.setHasResponded(true);

					// If the choice is already part of the answer, ...
					if (uiObj.helper.hasClass("partOfAnswer")) {
						// Remove it from the answer at its old position
						that._removeChoiceFromAnswer(uiObj.helper);
					}

					click.x = evt.clientX;
					click.y = evt.clientY;
				},
				drag: function(evt, uiObj) {
					// Modify the position of the draggable based on the css scale transform applied
					// to the test container
					var original = uiObj.originalPosition;
					var left = (evt.clientX - click.x + original.left) / app.testScale;
					var top = (evt.clientY - click.y + original.top) / app.testScale;

					totalDragEventCount++;

					uiObj.position = {
						left: left,
						top: top
					};

					that._currentCursorLeft = that._getCursorLeft($(this), uiObj.position.top, uiObj.position.left);
					if (that._currentInsertCursor) {
						that._currentInsertCursor.css({
							"left": `${that._currentCursorLeft}px`
						});
					}
				},
				stop: function(evt, uiObj) {
					//logging.logVerbose("Drag Stop");

					// If the choice is no longer part of the answer...
					if (!uiObj.helper.hasClass("partOfAnswer")) {
						// Deselect the choice
						uiObj.helper.removeClass("draggableChoiceSelected").addClass("draggableChoiceUnselected");
					} else {
						uiObj.helper.removeClass("draggableChoiceDragging").addClass("draggableChoiceSelected");
					}

					// Update the answer
					that._updateAnswer();
				}
			});

			// Create an anchor pixel at the bottom of the choice div to set the text baseline properly
			var anchorImage = $("<img/>", {
				"src": "images/1x1.gif",
				"css": {
					"position": "relative",
					"margin-top": `${this._textMetrics.anchorImgMarginTop}px`
				}
			}).appendTo(choice);

			// Append it to the choice area
			this._testAreaDroppableWrapper.append(choice);
		}
	}
};

Letters2Meaning_Words2SentenceItem.prototype._getItemMetrics = function(params) {
	logging.logVerbose(`Letters2Meaning_Words2SentenceItem._getItemMetrics(${JSON.stringify(params)})`);

	var itemMetrics = undefined;

	if (params) {
		// Extract parameters
		var frameWidth          = params.frameWidth;
		var frameHeight         = params.frameHeight;
		var fontFamilyCSS       = params.fontFamilyCSS;
		var initialFontSize     = params.initialFontSize;
		var fontWeightCSS       = params.fontWeightCSS;
		var lineSpacingRatio    = params.lineSpacingRatio;
		var minMarginX          = params.minMarginX;

		if (frameWidth && frameHeight) {
			// Get the width of the widest choice defined at the initial font size
			var widestChoice = app.getWidestChoice(this._itemDef, fontFamilyCSS, `${initialFontSize}px`, fontWeightCSS);
			var widestChoiceIdx = widestChoice.widestChoiceIdx;

			// Get the font size that allows the choices to fit in the given frame, based on the maximum choice width
			itemMetrics = this._adaptChoicesToFrame({
				"textToFit": this._itemDef.choices[widestChoiceIdx],
				"maxChoiceWidth": widestChoice.maxChoiceWidth,
				"numChoices": this._itemDef.choices.length,
				"frameWidth": frameWidth,
				"frameHeight": frameHeight,
				"fontFamilyCSS": fontFamilyCSS,
				"initialFontSize": initialFontSize,
				"fontWeightCSS": fontWeightCSS,
				"lineSpacingRatio": lineSpacingRatio,
				"minMarginX": minMarginX
			});
		}
	}

	return itemMetrics;
};

Letters2Meaning_Words2SentenceItem.prototype._adaptChoicesToFrame = function(params) {
	logging.logVerbose(`Letters2Meaning_Words2SentenceItem._adaptChoicesToFrame(${JSON.stringify(params)})`);

	var adaptedMetrics = undefined;

	if (params) {
		// Extract parameters
		var textToFit           = params.textToFit;
		var maxChoiceWidth      = params.maxChoiceWidth;
		var numChoices          = params.numChoices;
		var frameWidth          = params.frameWidth;
		var frameHeight         = params.frameHeight;
		var fontFamilyCSS       = params.fontFamilyCSS;
		var initialFontSize     = params.initialFontSize;
		var fontWeightCSS       = params.fontWeightCSS;
		var lineSpacingRatio    = params.lineSpacingRatio;
		var minMarginX          = params.minMarginX;

		if (textToFit && maxChoiceWidth && numChoices && frameWidth && frameHeight) {
			var marginXLeft = 0;
			var marginXRight = 0;
			var choiceMetrics;
			var fontSize;
			var largestFontSize = 0;
			var bestColumnCount;
			var metricsByColumnCount = [];
			var numLines;
			var blockWidth;
			var blockHeight;

			// Iterate column counts (from 2 to 6)
			for (var columnCount = 2; columnCount <= 6; columnCount++) {
				// Start from scratch
				maxChoiceWidth = params.maxChoiceWidth;
				fontSize = initialFontSize;

				// Compute block width (columns plus inter-column spacing)
				blockWidth = (maxChoiceWidth * columnCount) + ((2 * minMarginX) * (columnCount - 1));
				numLines = Math.ceil(numChoices / columnCount);
				//logging.logVerbose("  columnCount = " + columnCount + ", numLines = " + numLines);

				// Iterate the font size until the metrics meet the width constraint from the frame
				while (((blockWidth + (2 * minMarginX)) > frameWidth) && (fontSize > 9)) {
					// Decrease the font size accordingly
					fontSize = Math.max(9, Math.floor(fontSize * (frameWidth / (blockWidth + (2 * minMarginX)))));
					//logging.logVerbose("  Shrinking font size (width) to " + fontSize);

					// Re-measure the widest choice at the new font size
					choiceMetrics = app.measureText(fontFamilyCSS, `${fontSize}px`, fontWeightCSS, textToFit);
					//logging.logVerbose("  choiceMetrics (width): " + JSON.stringify(choiceMetrics));

					// Update the widths
					maxChoiceWidth = choiceMetrics.measureWidth;
					blockWidth = (maxChoiceWidth * columnCount) + ((2 * minMarginX) * (columnCount - 1));
					//logging.logVerbose("  new maxChoiceWidth = " + maxChoiceWidth);
					//logging.logVerbose("  new blockWidth = " + blockWidth);
				}

				// Make sure we've measured the font at the current font size
				if (!choiceMetrics) {
					choiceMetrics = app.measureText(fontFamilyCSS, `${fontSize}px`, fontWeightCSS, textToFit);
				}
				var fontMetrics = app.getFontMetrics(fontFamilyCSS, `${fontSize}px`, fontWeightCSS);
				var lineSpacing = Math.floor((fontSize * (lineSpacingRatio - 1)));

				// Width constraint met -- how about the height?
				blockHeight = numLines * choiceMetrics.measureHeight;
				//logging.logVerbose("  blockHeight = " + blockHeight);
				while ((blockHeight > frameHeight) && (fontSize > 9)) {
					// Decrease the font size accordingly
					fontSize = Math.max(9, Math.floor(fontSize * (frameHeight / blockHeight)));
					//logging.logVerbose("  Shrinking font size (height) to " + fontSize);

					// Re-measure the widest choice at the new font size
					choiceMetrics = app.measureText(fontFamilyCSS, `${fontSize}px`, fontWeightCSS, textToFit);
					//logging.logVerbose("  choiceMetrics (height): " + JSON.stringify(choiceMetrics));

					// Update block sizes
					lineSpacing = Math.floor((fontSize * (lineSpacingRatio - 1)));
					blockHeight = numLines * choiceMetrics.measureHeight;
					maxChoiceWidth = choiceMetrics.measureWidth;
					blockWidth = (maxChoiceWidth * columnCount) + ((2 * minMarginX) * (columnCount - 1));
				}
				//logging.logVerbose("  new blockHeight = " + blockHeight);
				//logging.logVerbose("  new maxChoiceWidth = " + maxChoiceWidth);
				//logging.logVerbose("  new blockWidth = " + blockWidth);

				metricsByColumnCount[columnCount] = {
					"columnCount": columnCount,
					"marginXLeft": (frameWidth - blockWidth) / 2,
					"marginYTop": (frameHeight - blockHeight) / 2,
					"fontSize": fontSize,
					"maxChoiceWidth": maxChoiceWidth,
					"blockWidth": blockWidth,
					"blockHeight": blockHeight,
					"lineSpacing": lineSpacing
				};

				// Different column counts give different font sizes; the "best"
				// column count is the one giving the largest font size
				if (fontSize > largestFontSize) {
					largestFontSize = fontSize;
					bestColumnCount = columnCount;
					//logging.logVerbose("bestColumnCount = " + bestColumnCount + ", largestFontSize = " + largestFontSize);
				}
			}

			adaptedMetrics = metricsByColumnCount[bestColumnCount];
		}
	}

	return adaptedMetrics;
};

Letters2Meaning_Words2SentenceItem.prototype._getChoiceLayout = function(columnCount, marginX) {
	logging.logVerbose("Letters2Meaning_Words2SentenceItem._getChoiceLayout()");

	var layout = [];

	// Get the layout of the choice area
	var parentCSS = this._choiceAreaFrame.css([
		"left",
		"width",
		"height"
	]);
	var parentLeft = app.getCSSPropertyAsNumber(parentCSS.left);
	var parentWidth = app.getCSSPropertyAsNumber(parentCSS.width);
	var parentHeight = app.getCSSPropertyAsNumber(parentCSS.height);

	// Define a grid on the choice area based on the current font metrics
	var numCols = columnCount;
	var numRows = Math.floor(parentHeight / this._textMetrics.height);
	var marginTop = (parentHeight % this._textMetrics.height) / 2;
	var columnStride = (parentWidth - (2 * marginX)) / numCols;
	var leftmostColumnCenterX = marginX + (columnStride / 2);

	logging.logVerbose(`parentWidth = ${parentWidth}, maxChoiceWidth = ${this._maxChoiceWidth}, numCols = ${numCols}, numRows = ${numRows}, marginTop = ${marginTop}, leftmostColumnCenterX = ${leftmostColumnCenterX}, columnStride = ${columnStride}`);

	// Set up an array of row/column pairs so we can randomly select them
	var cellArray = [];
	for (var i = 0; i < numRows; i++) {
		for (var j = 0; j < numCols; j++) {
			cellArray.push({"col": j, "row": i});
		}
	}

	// Select random cells to hold the choices
	var cellIdx;
	var cell;
	for (var i = 0; i < this._itemDef.choices.length; i++) {
		// Select a random cell index
		cellIdx = Math.floor(Math.random() * cellArray.length);

		// Remove the randomly-selected cell from the array,
		// so we don't choose it again
		cell = cellArray.splice(cellIdx, 1)[0];

		// Generate a layout coordinate for the cell
		layout.push({
			"x": Math.floor(leftmostColumnCenterX + (cell.col * columnStride)),
			"y": Math.floor(marginTop + (cell.row * this._textMetrics.height))
		});
	}

	//logging.logVerbose("  layout: " + JSON.stringify(layout));
	return layout;
};

Letters2Meaning_Words2SentenceItem.prototype._addChoiceToAnswer = function(answerArea, choice) {
	logging.logVerbose("Letters2Meaning_Words2SentenceItem._addChoiceToAnswer()");

	// Signal that the choice should now be considered part of the answer
	choice.addClass("partOfAnswer");

	// Make sure choice is selected
	choice.removeClass("draggableChoiceUnselected").addClass("draggableChoiceSelected");

	// Get the answer area index (based on ID) of the answer area receiving the choice
	var answerAreaIdx = Number(answerArea.attr("id").replace("words2SentenceAnswerArea", "")) - 1;

	// Is the choice punctuation?
	var choiceText = choice.text();
	var punctIdx = $.inArray(choiceText, this._punctuation);
	var isPunct = (punctIdx != -1);

	// Insert the object into our answer array based on answer area & current center-X coordinate
	var centerX = app.getChoiceCenterX(choice);
	for (var i = 0; i < this._answerChoices.length; i++) {
		var answerChoice = this._answerChoices[i];
		if (((answerChoice.answerAreaIdx == answerAreaIdx) &&
			(answerChoice.centerX > centerX)) ||
			(answerChoice.answerAreaIdx > answerAreaIdx)) {
			break;
		}
	}
	this._answerChoices.splice(i, 0, {"answerAreaIdx": answerAreaIdx, "centerX": centerX, "choice": choice, "isPunct": isPunct});
};

Letters2Meaning_Words2SentenceItem.prototype._removeChoiceFromAnswer = function(choice) {
	logging.logVerbose("Letters2Meaning_Words2SentenceItem._removeChoiceFromAnswer()");

	// Signal that the choice should no longer be considered part of the answer
	choice.removeClass("partOfAnswer");

	// Remove the object from our answer array
	var choiceId = choice.attr("id");
	logging.logVerbose(`  choice ID: ${choiceId}`);
	for (var i = 0; i < this._answerChoices.length; i++) {
		if (this._answerChoices[i].choice.attr("id") == choiceId) {
			logging.logVerbose(`  index in answerChoices: ${i}`);
			break;
		}
	}
	this._answerChoices.splice(i, 1);
};

Letters2Meaning_Words2SentenceItem.prototype._returnChoiceToOrigin = function(choice) {
	logging.logVerbose("Letters2Meaning_Words2SentenceItem._returnChoiceToOrigin()");

	// Make sure choice is deselected
	choice.removeClass("draggableChoiceSelected").addClass("draggableChoiceUnselected");

	// Put the choice back in its original position
	var choiceData = this._choiceData[choice.attr("id")];
	if (choiceData) {
		logging.logVerbose(`originalTop: ${choiceData.originalTop}, originalLeft: ${choiceData.originalLeft}`);
		choice.animate({
			"top": choiceData.originalTop,
			"left": choiceData.originalLeft
		}, 500);
	}
};

Letters2Meaning_Words2SentenceItem.prototype._getCursorLeft = function(choice, top, left) {
	// "Cursor" in this context does -not- mean the mouse cursor.  It's the green bar that
	// indicates where a dragged item will be placed.
	var cursorLeft = 0;

	var choiceWidth = app.getCSSPropertyAsNumber(choice.css("width"));
	var centerX = left + (choiceWidth / 2);

	// Get the index of the answer area in the _answerAreas table (derived from ID)
	if (!this._answerAreaIdToIndexRegExp) {
		this._answerAreaIdToIndexRegExp = /^words2SentenceAnswerArea(\d+)$/;
	}
	var answerAreaId = this._currentAnswerArea.attr("id").replace(this._answerAreaIdToIndexRegExp, "$1");
	var answerAreaIdx = app.getCSSPropertyAsNumber(answerAreaId) - 1;
	var answerAreaLeft = this._answerAreas[answerAreaIdx].left;
	var answerAreaWidth = this._answerAreas[answerAreaIdx].width;

	if (this._answerChoices.length == 0) {
		cursorLeft = (answerAreaWidth / 2) - (this._cursorWidth / 2);
	} else {
		// Find choices in the current answer area
		var leftIdx = -1;
		var rightIdx = -1;
		for (var i = 0; i < this._answerChoices.length; i++) {
			if (this._answerChoices[i].answerAreaIdx == answerAreaIdx) {
				if (leftIdx == -1) {
					leftIdx = i;
				}
				rightIdx = i;
			}
		}
		// If no choices found in the answer area...
		if ((leftIdx == -1) || (rightIdx == -1)) {
			// ... use the center of the answer area as the cursor position
			cursorLeft = (answerAreaWidth / 2) - (this._cursorWidth / 2);
		} else {
			// Metrics for choice on left side of cursor
			var leftCenterX = this._answerChoices[leftIdx].centerX;
			var leftHalfWidth = this._answerChoices[leftIdx].width / 2;
			var leftLeftX = leftCenterX - leftHalfWidth;
			var leftRightX = leftCenterX + leftHalfWidth;

			// Metrics for choice on right side of cursor
			var rightCenterX = this._answerChoices[rightIdx].centerX;
			var rightHalfWidth = this._answerChoices[rightIdx].width / 2;
			var rightLeftX = rightCenterX - rightHalfWidth;
			var rightRightX = rightCenterX + rightHalfWidth;

			if (centerX <= leftCenterX) {
				// Cursor on immediate left of leftmost choice in answer area
				cursorLeft = leftLeftX - this._cursorWidth - answerAreaLeft;
			} else if (centerX > rightCenterX) {
				//logging.logVerbose("leftIdx = " + leftIdx + ", rightIdx = " + rightIdx + ", left = " + left + ", choiceWidth = " + choiceWidth + ", centerX = " + centerX + ", leftCenterX = " + leftCenterX + ", rightCenterX = " + rightCenterX);
				// Cursor on immediate right of rightmost choice in answer area
				cursorLeft = rightRightX - answerAreaLeft;
			} else {
				// Cursor in space between choices in answer area -- figure out which choices
				for (var i = leftIdx; i <= rightIdx; i++) {
					// Use existing left-hand choice metrics; get right-hand choice metrics
					rightCenterX = this._answerChoices[i].centerX;
					rightHalfWidth = this._answerChoices[i].width / 2;
					rightLeftX = rightCenterX - rightHalfWidth;
					rightRightX = rightCenterX + rightHalfWidth;

					// Is this where the cursor belongs?
					if ((centerX > leftCenterX) && (centerX <= rightCenterX)) {
						// Yes -- put the cursor in the middle of the space between the choices
						cursorLeft = ((leftRightX + rightLeftX) / 2) - (this._cursorWidth / 2) - answerAreaLeft;
						break;
					}

					// Right-hand choice will be left-hand choice on next loop iteration
					leftCenterX = rightCenterX;
					leftLeftX = rightLeftX;
					leftRightX = rightRightX;
				}
			}
		}
	}

	return cursorLeft;
};

Letters2Meaning_Words2SentenceItem.prototype._getAllChoicesOverallWidth = function(fontFamilyCSS, fontSizeCSS, fontWeightCSS) {
	logging.logVerbose("Letters2Meaning_Words2SentenceItem._getAllChoicesOverallWidth()");

	var overallWidth = 0;

	var choiceMetrics;
	for (var i = 0; i < this._itemDef.choices.length; i++) {
		choiceMetrics = app.measureText(fontFamilyCSS, fontSizeCSS, fontWeightCSS, this._itemDef.choices[i]);
		overallWidth += choiceMetrics.measureWidth;
	}

	// Include all of the whitespace between the answer choices
	overallWidth += (this._itemDef.choices.length > 1) ?
		((this._itemDef.choices.length - 1) * this._textMetrics.spaceWidth) :
		0;

	return overallWidth;
};

Letters2Meaning_Words2SentenceItem.prototype._arrangeAnswerChoices = function() {
	logging.logVerbose("Letters2Meaning_Words2SentenceItem._arrangeAnswerChoices()");

	/*** Pass #1: Group the answer choices into lines (determine line breaks) ***/
	var lineData = [];
	var currentAnswerAreaIdx = 0;
	var currentLineStartAnswerChoiceIdx = 0;
	var currentLineEndAnswerChoiceIdx = 0;
	var currentLineAnswerWidth = 0;
	var spacing = 0;
	var maxAnswerLineWidth = 0;
	var i = 0;
	var spaceWidth = this._textMetrics.spaceWidth;

	for (; i < this._answerChoices.length; i++) {
		// Get the width of the current answer choice
		var choiceData = this._choiceData[this._answerChoices[i].choice.attr("id")];
		var choiceWidth = choiceData.metrics.measureWidth;

		// Get the width of the next answer choice (lookahead for punctuation)
		var choiceWidth2 = 0;
		var isNextAnswerChoicePunct = false;
		if (i < this._answerChoices.length - 1) {
			isNextAnswerChoicePunct =  this._answerChoices[i + 1].choice.isPunct;
			var choiceData2 = this._choiceData[this._answerChoices[i + 1].choice.attr("id")];
			choiceWidth2 = choiceData2.metrics.measureWidth;
		}

		// Test if adding it to the current line would be OK
		var widthLimit = (this._answerAreas[currentAnswerAreaIdx].width - (2 * this._answersInAnswerAreaMarginX));
		if (((currentLineAnswerWidth + spacing + choiceWidth) > widthLimit) ||
			(isNextAnswerChoicePunct &&
			((currentLineAnswerWidth + spacing + choiceWidth + choiceWidth2) > widthLimit))) {
			// Adding the choice would make the line too long, so save what we've got.
			// Push the current start & end answer choice indices into the lineData array
			lineData.push({
				"startIdx": currentLineStartAnswerChoiceIdx,
				"endIdx": currentLineEndAnswerChoiceIdx,
				"width": currentLineAnswerWidth
			});

			// Keep track of the longest line
			if (currentLineAnswerWidth > maxAnswerLineWidth) {
				maxAnswerLineWidth = currentLineAnswerWidth;
			}

			// Start a new line
			currentAnswerAreaIdx++;
			currentLineStartAnswerChoiceIdx = i;
			currentLineEndAnswerChoiceIdx = i;
			currentLineAnswerWidth = choiceWidth;
			spacing = 0;
		} else {
			// Otherwise, it fits, so extend the current line's index range
			currentLineEndAnswerChoiceIdx = i;

			// Update the current line's overall width
			currentLineAnswerWidth += (spacing + choiceWidth);

			// Subsequent answer choices on this line will require spacing
			spacing = spaceWidth;
		}
	}
	// If we have measured answer choices we haven't saved yet, ...
	if (currentLineAnswerWidth > 0) {
		// ... save them into the array
		// Push the current start & end answer choice indices into the lineData array
		lineData.push({
			"startIdx": currentLineStartAnswerChoiceIdx,
			"endIdx": currentLineEndAnswerChoiceIdx,
			"width": currentLineAnswerWidth
		});

		// Keep track of the longest line
		if (currentLineAnswerWidth > maxAnswerLineWidth) {
			maxAnswerLineWidth = currentLineAnswerWidth;
		}
	}
	/*** End Pass #1 -- lineData now has indices into _answerChoices forming lines ***/

	logging.logVerbose(`Pass 1: lineData = ${JSON.stringify(lineData)}`);

	/*** Pass #2 -- process lineData into answer areas ***/
	for (var i = 0; i < lineData.length; i++) {
		var currentChoiceLeftX = this._answerAreas[i].left +                                    // Left end of the current answer area
									(this._answerAreas[i].width - maxAnswerLineWidth) / 2;      // Space to center block (left-justified) in answer area

		// Iterate through the answer choices for this line
		for (var j = lineData[i].startIdx; j <= lineData[i].endIdx; j++) {
			var choiceData = this._choiceData[this._answerChoices[j].choice.attr("id")];
			var choiceWidth = choiceData.metrics.measureWidth;

			// If the choice is not punctuation, ...
			if ((j > lineData[i].startIdx) && !this._answerChoices[j].isPunct) {
				// ... add the space between words
				currentChoiceLeftX += spaceWidth;
			}

			// Reposition the choice in the answer area
			this._answerChoices[j].choice.css({
				"top": this._answerAreas[i].top + this._textMetrics.topOffset,
				"left": currentChoiceLeftX
			});

			// Update the choice's answer area & center-X coordinate in the list
			this._answerChoices[j].answerAreaIdx = i;
			this._answerChoices[j].centerX = currentChoiceLeftX + (choiceWidth / 2);
			this._answerChoices[j].width = choiceWidth;

			// Prepare for the next answer choice
			currentChoiceLeftX += choiceWidth;
		}
	}
};

Letters2Meaning_Words2SentenceItem.prototype._getAnswer = function() {
	logging.logVerbose("Letters2Meaning_Words2SentenceItem._getAnswer()");

	var currentAnswer = "";
	for (var i = 0; i < this._answerChoices.length; i++) {
		currentAnswer += this._answerChoices[i].choice.text();
		if (i < (this._answerChoices.length - 1)) {
			currentAnswer += " ";
		}
	}

	logging.logVerbose(`  current answer: ${currentAnswer}, previous answer = ${this.currentAnswer}`);

	if (currentAnswer !== this.currentAnswer) {
		this.currentAnswer = currentAnswer;
		this.answerCorrect = (this.currentAnswer == this._itemDef.correctAnswer);
		logging.logVerbose(`  answerCorrect = ${this.answerCorrect}`);

		// Log the response
		logging.logItemResponse(app.curPageName,
			this._itemDef.itemId,
			this._itemDef.itemLabel,
			-1,
			this.currentAnswer,
			this.answerCorrect,
			true);
	}
};

Letters2Meaning_Words2SentenceItem.prototype._updateAnswer = function() {
	logging.logVerbose("Letters2Meaning_Words2SentenceItem._updateAnswer()");

	// Update the positions of the answer choices to tidy everything up
	this._arrangeAnswerChoices();
	$('.ui-draggable').removeClass('clicked');

	this._getAnswer();

	// Update the "Next" button -- only enable it if we have an answer using all of the choices
	var enabled = (
		(this.currentAnswer != null) &&
		(typeof this.currentAnswer !== "undefined") &&
		(this.currentAnswer !== "") &&
		(this._answerChoices.length >= this._itemDef.choices.length)
	);
	app.enableNextButton(enabled);
};
