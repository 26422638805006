function l2m_testDefFactory() {
	var testDefAdaptive = {
		"id": 1001,
		"name": "March 2016 Test",
		"grades": {
			"0": {
				"testType": "adaptive",
				"initialItemTheta": -2.15,
				"initialItemType": "letterIdentification",
				"maxItemCount": 25,
				"parametersByItemType": {
					"letterIdentification": {
						"maxItemCount": 12,
						"minItemCount": 3
					},
					"soundIdentification": {
						"maxItemCount": 12,
						"minItemCount": 3
					},
					"syllableIdentification": {
						"maxItemCount": 12,
						"minItemCount": 3
					},
					"wordRecognition": {
						"maxItemCount": 12,
						"minItemCount": 6
					},
					"letters2Word": {
						"maxItemCount": 12,
						"minItemCount": 5
					},
					"words2Sentence": {
						"maxItemCount": 15,
						"minItemCount": 0
					}
				},
				"itemTypeThresholds": {
					"letterIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 4,
						"results": [
							{ "variable": "_itemType", "value": "finale" }
						]
					},
					"letterIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThanOrEqualTo",
						"value": -1.0,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -2.15 }
						]
					},
					"soundIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -1.0,
						"results": [
							{ "variable": "_itemType", "value": "syllableIdentification" },
							{ "variable": "_itemTheta", "value": -1.6 }
						]
					},
					"syllableIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -0.65,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": -1.1 }
						]
					},
					"wordRecognitionAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -0.15,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": -1.05 }
						]
					},
					"letters2WordAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 0.9,
						"results": [
							{ "variable": "_itemType", "value": "words2Sentence" },
							{ "variable": "_itemTheta", "value": -0.4 }
						]
					}
				}
			},
			"1": {
				"testType": "adaptive",
				"initialItemTheta": -1.6,
				"initialItemType": "syllableIdentification",
				"maxItemCount": 24,
				"parametersByItemType": {
					"letterIdentification": {
						"maxItemCount": 12,
						"minItemCount": 0
					},
					"soundIdentification": {
						"maxItemCount": 12,
						"minItemCount": 0
					},
					"syllableIdentification": {
						"maxItemCount": 12,
						"minItemCount": 3
					},
					"wordRecognition": {
						"maxItemCount": 12,
						"minItemCount": 6
					},
					"letters2Word": {
						"maxItemCount": 12,
						"minItemCount": 5
					},
					"words2Sentence": {
						"maxItemCount": 15,
						"minItemCount": 5
					}
				},
				"itemTypeThresholds": {
					"letterIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 4,
						"results": [
							{ "variable": "_itemType", "value": "finale" }
						]
					},
					"letterIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThanOrEqualTo",
						"value": -1.0,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -2.05 }
						]
					},
					"soundIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letterIdentification" },
							{ "variable": "_itemTheta", "value": -1.05 }
						]
					},
					"soundIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -1.0,
						"results": [
							{ "variable": "_itemType", "value": "syllableIdentification" },
							{ "variable": "_itemTheta", "value": -1.55 }
						]
					},
					"syllableIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.45 }
						]
					},
					"syllableIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -0.55,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": -1.2 }
						]
					},
					"wordRecognitionDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "syllableIdentification" },
							{ "variable": "_itemTheta", "value": -0.3 }
						]
					},
					"wordRecognitionAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -0.15,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": -1.1 }
						]
					},
					"letters2WordDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 5,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.1 }
						]
					},
					"letters2WordAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 0.9,
						"results": [
							{ "variable": "_itemType", "value": "words2Sentence" },
							{ "variable": "_itemTheta", "value": -0.4 }
						]
					},
					"words2SentenceDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 5,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 0.75 }
						]
					}
				}
			},
			"2": {
				"testType": "adaptive",
				"initialItemTheta": -1.2,
				"initialItemType": "wordRecognition",
				"maxItemCount": 25,
				"parametersByItemType": {
					"letterIdentification": {
						"maxItemCount": 12,
						"minItemCount": 0
					},
					"soundIdentification": {
						"maxItemCount": 12,
						"minItemCount": 0
					},
					"syllableIdentification": {
						"maxItemCount": 12,
						"minItemCount": 0
					},
					"wordRecognition": {
						"maxItemCount": 12,
						"minItemCount": 6
					},
					"letters2Word": {
						"maxItemCount": 12,
						"minItemCount": 6
					},
					"words2Sentence": {
						"maxItemCount": 15,
						"minItemCount": 6
					}
				},
				"itemTypeThresholds": {
					"letterIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 4,
						"results": [
							{ "variable": "_itemType", "value": "finale" }
						]
					},
					"letterIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThanOrEqualTo",
						"value": -1.0,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -2.05 }
						]
					},
					"soundIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letterIdentification" },
							{ "variable": "_itemTheta", "value": -1.0 }
						]
					},
					"soundIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -1.0,
						"results": [
							{ "variable": "_itemType", "value": "syllableIdentification" },
							{ "variable": "_itemTheta", "value": -1.2 }
						]
					},
					"syllableIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -0.8 }
						]
					},
					"syllableIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -0.7,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": -1.2 }
						]
					},
					"wordRecognitionDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "syllableIdentification" },
							{ "variable": "_itemTheta", "value": -0.3 }
						]
					},
					"wordRecognitionAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -0.15,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": -1.0 }
						]
					},
					"letters2WordDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.25 }
						]
					},
					"letters2WordAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 0.9,
						"results": [
							{ "variable": "_itemType", "value": "words2Sentence" },
							{ "variable": "_itemTheta", "value": -0.35 }
						]
					},
					"words2SentenceDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 0.9 }
						]
					}
				}
			},
			"3": {
				"testType": "adaptive",
				"initialItemTheta": -1.45,
				"initialItemType": "wordRecognition",
				"maxItemCount": 25,
				"parametersByItemType": {
					"letterIdentification": {
						"maxItemCount": 12,
						"minItemCount": 0
					},
					"soundIdentification": {
						"maxItemCount": 12,
						"minItemCount": 0
					},
					"syllableIdentification": {
						"maxItemCount": 12,
						"minItemCount": 0
					},
					"wordRecognition": {
						"maxItemCount": 15,
						"minItemCount": 6
					},
					"letters2Word": {
						"maxItemCount": 12,
						"minItemCount": 6
					},
					"words2Sentence": {
						"maxItemCount": 15,
						"minItemCount": 6
					}
				},
				"itemTypeThresholds": {
					"letterIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 4,
						"results": [
							{ "variable": "_itemType", "value": "finale" }
						]
					},
					"letterIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThanOrEqualTo",
						"value": -1.0,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.95 }
						]
					},
					"soundIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letterIdentification" },
							{ "variable": "_itemTheta", "value": -0.95 }
						]
					},
					"soundIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -1.0,
						"results": [
							{ "variable": "_itemType", "value": "syllableIdentification" },
							{ "variable": "_itemTheta", "value": -1.55 }
						]
					},
					"syllableIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -0.8 }
						]
					},
					"syllableIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -0.55,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": -1.2 }
						]
					},
					"wordRecognitionDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "syllableIdentification" },
							{ "variable": "_itemTheta", "value": -0.2 }
						]
					},
					"wordRecognitionAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -0.15,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": -1.0 }
						]
					},
					"letters2WordDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.2 }
						]
					},
					"letters2WordAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 0.95,
						"results": [
							{ "variable": "_itemType", "value": "words2Sentence" },
							{ "variable": "_itemTheta", "value": -0.25 }
						]
					},
					"words2SentenceDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 1.0 }
						]
					}
				}
			},
			"default": {
				"testType": "linear",
				"initialItemTheta": 1.1027484798996523,
				"initialItemType": "words2Sentence",
				"maxItemCount": 35,
				"initialItemId": 6012
			}
		}
	};

	return testDefAdaptive;
}
